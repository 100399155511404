export let moduleMenu = [
  {
    title: 'In-patient Service',
    action: 'hotel',
    menuItems: [
      {
        title: 'Nurse counter',
        link: { name: 'ipd.nursecounter' },
        permission: 'ipd-nurse-counter',
      },
    ],
    permission: 'ipd',
  },
]

export let moduleRoute = [
  {
    path: '/ipd/NurseCounter/:encounterId?',
    name: 'ipd.nursecounter',
    component: () => import('./NurseCounter'),
    props: true,
  },
]