import api from 'tantee-common/lib/api'
import * as helper from '@/modules/helperPacs'

export function openPacs(uid) {
  var popup = window.open(api.urlBuilder(['pacsViewer','viewer?StudyInstanceUIDs='+uid]), "", "location=no")
  if (popup.outerWidth < screen.availWidth || popup.outerHeight < screen.availHeight)
  {
    popup.moveTo(0,0);
    popup.resizeTo(screen.availWidth, screen.availHeight);
  }
}

export default helper