<template lang="html">
  <v-model-table model-name="Documents" 
    :model-api="['models','Document','Documents']"
    :model-api-search="computedFilter" 
    :api-with="['encounter','template']"
    api-order-by="encounterId,desc"
    group-by="encounterId"
    group-desc
    :headers="computedHeader" 
    :insertable="false" searchable serverPagination
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:toolBarItem="props">
      <v-checkbox class="mr-1" v-model="currentEncounterType" label="ผู้ป่วยนอก" value="AMB" hide-details single-line></v-checkbox>
      <v-checkbox class="mr-1" v-model="currentEncounterType" label="ผู้ป่วยใน" value="IMP" hide-details single-line></v-checkbox>
      <v-master-field v-model="currentTemplateCode" label="ชนิดเอกสาร"
        :custom-api="['models','Document','DocumentsTemplates','query']"
        custom-api-text="templateName"
        custom-api-value="templateCode" 
        clearable multiple customApiEagerLoad
        hide-details single-line
      ></v-master-field>
    </template>
    <template v-slot:group.header="props">
      <td :colspan="props.headers.length-1">
        <label class="font-weight-bold" v-if="props.group!=null">{{ props.group }} {{ props.items[0].encounter.clinic.clinicName }}</label>
        <label class="font-weight-bold" v-else>ไม่ระบุการเข้าตรวจ</label>
      </td>
      <td align="center">
        <v-icon class="mx-1" @click.stop="viewDocumentGroup(props.items)" v-if="props.items">mdi-view-carousel</v-icon>
      </td>
    </template>
    <template v-slot:item.created_at="props">
      <v-icon>{{ (props.item.status=='approved') ? 'mdi-checkbox-marked-circle' : 'mdi-timer-sand' }}</v-icon>
      <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
    </template>
    <template v-slot:item.category="props">
      <v-label-master group-key="documentCategory" :item-code="props.item.category"></v-label-master>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="viewDocument(props.item)">mdi-file-find</v-icon>
      <v-icon class="mx-1" @click.stop="printDocument(props.item.id)" v-if="!props.item.template || props.item.isScanned || props.item.template.isPrintable">print</v-icon>
    </template>
  </v-model-table>
</template>

<script>
import api from 'tantee-common/lib/api'
import {isUndefined,isEmpty} from 'lodash'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    currentEncounterType: ['AMB','IMP'],
    currentTemplateCode: undefined,
    headers: [
      {
        text: 'วันที่',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'คลินิก',
        value: 'encounter.clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'encounter.doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'หมวดหมู่เอกสาร',
        value: 'category',
        class: 'body-2'
      },
      {
        text: 'ชนิดเอกสาร',
        value: 'template.templateName',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    category: {
      type: String,
      default: undefined
    },
    folder: {
      type: String,
      default: undefined
    },
    status: {
      type: String,
      default: 'approved'
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedFilter() {
      let filter = {
        hn: this.hn,
        encounterId: this.encounterId,
        folder: this.folder,
        category: this.category,
        status: this.status,
      }
      if (isUndefined(this.hn)) filter.hn = null
      if (isUndefined(this.encounterId) && this.folder=='default') {
        if (!isEmpty(this.currentEncounterType)) filter['encounter$encounterType'] = this.currentEncounterType
        if (!isEmpty(this.currentTemplateCode)) filter['templateCode'] = this.currentTemplateCode
      }
      return filter
    },
    computedHeader() {
      return this.headers
    },
  },
  methods: {
    viewDocument(document) {
      this.$documentViewer(document)
    },
    viewDocumentGroup(document) {
      this.$documentViewer(document)
    },
  },
}
</script>

<style lang="css">
</style>
