import Vue from 'vue'
import DocumentViewer from '@/components/document/vDocumentViewerDialog.vue'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.documentViewer) this.$documentViewer = options.documentViewer;
    else if (options.parent && options.parent.$documentViewer) this.$documentViewer = options.parent.$documentViewer
  }
});

export default function (documents,options) {
  return new Promise(resolve => {
    const component = new Vue(Object.assign(DocumentViewer, {
      destroyed: () => {
        document.getElementById('app').removeChild(component.$el)
        resolve(component.value)
      }
    }))
    Object.assign(component, options,{documents: documents})
    component.$store = this.$store
    component.$vuetify = this.$vuetify
    component.$loading = this.$loading
    component.$mount()
    document.getElementById('app').appendChild(component.$el)
  })
}
