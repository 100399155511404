<template lang="html">
  <v-card>
    <v-toolbar card>
      <v-toolbar-title>Screening</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row>
          <v-col cols="12">
            <v-textarea label="ข้อมูลประวัติเบื้องต้น" auto-grow></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-radio-group label="สภาวะปัจจุบัน" row>
              <v-radio label="เดินมา"></v-radio>
              <v-radio label="รถนั่ง"></v-radio>
              <v-radio label="รถนอน"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary">บันทึก</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
