<template lang="html">
  <v-text-field v-bind:value="value" v-bind="$attrs" v-on="$listeners"
    label="HN"
    append-outer-icon="search"
    @click:append-outer="dialogSearchHN"
    @keydown.114.prevent="dialogSearchHN"
    @keydown.enter.prevent="checkHN"
    @blur="checkHN"
    :messages="currentPatientName"
    v-model="currentHnInput"
    :loading="isLoadingCombined" :disabled="isDisabledCombined"
    :hide-details="hideDetails"
  ></v-text-field>
</template>

<script>
import patient from '@/modules/helperPatient'
import isUndefined from 'lodash'

export default {
  data: ()=>({
    currentPatientName: '',
    currentPatient: undefined,
    currentHnInput: null,
    isLoading: false,
  }),
  props: {
    value: {
      default: null
    },
    loadDetail: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    value: function(newValue) {
      if (this.currentHnInput!=newValue) {
        this.currentHnInput = newValue
        this.checkHN()
      }
    },
  },
  methods: {
    dialogSearchHN() {
      this.$hn().then((res)=>{
        if (res!==false) {
          this.$emit('input',res)
          if (this.loadDetail) {
            this.$nextTick(()=>{
              this.checkHN()
            })
          } else {
            this.$emit('patientChange',res)
          }
        }
      })
    },
    checkHN() {
      if ((this.currentHnInput !== null) && (isUndefined(this.currentPatient) || this.currentHnInput !== this.currentPatient.hn) && (!this.isLoading)) {
        this.isLoading = true
        patient.getPatientByHn(this.currentHnInput).then((returnData)=>{
          patient.name(returnData.name_th).then((returnName)=>{
            this.currentPatientName = returnName
          })
          this.currentPatient = returnData
          this.$emit('patientChange',returnData.hn)
        }).catch((e)=>{
          void e
          this.reset()
        }).finally(()=>{
          this.isLoading = false
        })
      } else {
        if (this.currentHnInput == null) this.reset()
      }
    },
    reset() {
      this.currentPatientName = ''
      this.currentPatient = undefined
      this.currentHnInput = null
      this.$emit('input',undefined)
      this.$emit('patientChange',undefined)
    }
  },
  computed: {
    isLoadingCombined() {
      return this.isLoading || this.loading
    },
    isDisabledCombined() {
      return this.isLoading || this.disabled
    }
  },
  mounted() {
    if (this.currentHnInput!=this.value) {
      this.currentHnInput=this.value
      this.checkHN()
    }
  }
}
</script>

<style lang="css">
</style>
