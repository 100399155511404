<template lang="html">
  <v-card>
    <v-toolbar card>
      <v-toolbar-title>บันทึก Vital Sign</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form-pad ref="vitalsignPad" v-model="vitalsignData">
        <template v-slot="{data, rules}">
          <v-container fluid pa-0>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="data.height" :rules="[rules.numeric()]" label="Height" append="cm"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.weight" label="Weight" append="Kg"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.painScore" :rules="[rules.numeric(),rules.range(0,10)]" label="Pain Score"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.temperature" :rules="[rules.numeric(),rules.range(30,45)]" label="Temperature" append="°C"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.heartRate" label="Heart Rate" append="/min"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.respiratoryRate" label="Respiratory Rate" append="/min"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.bloodPressureSystolic" label="BP Systolic" append="mmHg"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.bloodPressureDiastolic" label="BP Systolic" append="mmHg"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="data.oxygenSaturation" label="O2 Saturation" append="%"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-form-pad>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary">บันทึก</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: ()=>({
    vitalsignData: {},
  }),
  methods: {
    reset() {
      this.vitalsignPad.reset()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
