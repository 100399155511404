<template lang="html">
  <v-card>
    <v-btn color="primary" fab small top right absolute @click="onDelete" v-if="$store.getters.getCurrentUser && ($store.getters.getCurrentUser.username=='poompis.pat' || $store.getters.getCurrentUser.username=='supachai.nil')"><v-icon>delete</v-icon></v-btn>

    <v-card-text v-if="document.isScanned" class="pa-0">
      <v-carousel 
        height="250" 
        :show-arrows-on-hover="true"
        hide-delimiter-background
        light
      >
        <v-carousel-item
          v-for="(assetData, i) in document.data"
          :key="i"
           @click.stop="viewDocument(document)"
        >
          <v-asset-viewer :asset="assetData"></v-asset-viewer>
        </v-carousel-item>
      </v-carousel>
    </v-card-text>
    <v-card-text class="pb-0" v-else>
      <div align="center">
        <v-icon :size="150">mdi-text-box</v-icon>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { isPlainObject,isUndefined } from 'lodash'
import helperAsset from '@/modules/helperAsset'

export default {
  data: ()=>({
    imgSrc: undefined,
    isLoading: false,
    viewer: false,
  }),
  props: {
    document: {
      type: [Object, Array],
      default: ()=>({})
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete',this.document)
    },
    viewDocument(document) {
      this.$documentViewer(document)
    }
  },
  watch: {
    document: {
      handler(newVal) {

      },
      deep: true
    },
  },
}
</script>

<style lang="css" scoped>
</style>
