<template lang="html">
  <form action="http://eservices.nhso.go.th/eServices/mobile/j_spring_security_check" target="_blank" ref="nhsoForm" method="post">
    <input type="hidden" name="username" ref="personId">
    <input type="hidden" name="password" ref="dob">
    <input type="hidden" name="defaultReal" value="KDZHV">
    <input type="hidden" name="defaultRealHash" value="225640492">
    <v-btn color="primary" :disabled="!hn" @click="performCheck">ตรวจสอบสิทธิ์</v-btn>
  </form>
</template>

<script>
import helperPatient from '@/modules/helperPatient'
import helperDateTime from 'tantee-common/lib/helperDateTime'

export default {
  data: ()=>({

  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  methods: {
    performCheck() {
      if (this.hn) {
        helperPatient.getPatientByHn(this.hn).then((patientData)=>{
          this.$refs.personId.value = patientData.personId
          this.$refs.dob.value = helperDateTime.toDateDataBuddhist(patientData.dateOfBirth)
          this.$refs.nhsoForm.submit()
        }).catch((e)=>{
          void e
        })
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
