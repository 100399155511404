<template lang="html">
  <v-dialog @input="change" value="true" max-width="80vw" max-height="80vh" @keydown.esc="cancel">
    <v-card>
      <v-form @submit.prevent="search">
      <v-toolbar flat>
        <v-text-field v-model="criteria.hn" class="px-1" label="HN" single-line hide-details></v-text-field>
        <v-text-field v-model="criteria.firstName" class="px-1" label="ชื่อ" single-line autofocus hide-details></v-text-field>
        <v-text-field v-model="criteria.lastName" class="px-1" label="นามสกุล" single-line hide-details></v-text-field>
        <v-text-field v-model="criteria.personId" class="px-1" label="เลขประจำตัวประชาชน" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn icon type="submit">
          <v-icon>search</v-icon>
        </v-btn>
      </v-toolbar>
      </v-form>
      <v-card-text class="pa-0">
        <v-model-table
          v-model="selectedItem"
          model-name="Patients"
          model-key="hn"
          :model-api="['models','Patient','Patients']"
          :model-api-search="searchFilter"
          :headers="headers"
          hide-toolbar
          server-pagination
          flat
          show-select
        >
          <template v-slot:item.name_th="{item}">
            <v-label-name :name-data="item.name_th"></v-label-name>
          </template>
          <template v-slot:item.name_en="{item}">
            <v-label-name :name-data="item.name_en"></v-label-name>
          </template>
        </v-model-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn color="primary" @click="choose" :disabled="!selectedItem.length">เลือก</v-btn>
        <v-btn color="grey" @click="cancel">ยกเลิก</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isUndefined} from 'lodash'

export default {
  data: ()=>({
    value: false,
    selectedItem: [],
    searchFilter: null,
    criteria: {},
    headers: [
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name_th',
        class: 'body-2'
      },
      {
        text: 'Name',
        value: 'name_en',
        class: 'body-2'
      },
      {
        text: 'เลขประจำตัวประชาชน',
        value: 'personId',
        class: 'body-2'
      },
    ]
  }),
  methods: {
    choose() {
      if (this.selectedItem.length>0) {
        this.$emit('result', this.selectedItem[0].hn)
        this.value = this.selectedItem[0].hn
      } else {
        this.$emit('result', false)
        this.value = false
      }
      this.$destroy()
    },
    cancel() {
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    },
    search() {
      let tmpFilter = []
      if (!isUndefined(this.criteria.hn) && this.criteria.hn.length>0) tmpFilter.push(['hn','like',this.criteria.hn+'%'])
      if (!isUndefined(this.criteria.firstName) && this.criteria.firstName.length>0) tmpFilter.push(['names$firstName','like',this.criteria.firstName+'%'])
      if (!isUndefined(this.criteria.lastName) && this.criteria.lastName.length>0) tmpFilter.push(['names$lastName','like',this.criteria.lastName+'%'])
      if (!isUndefined(this.criteria.personId) && this.criteria.personId.length>0) tmpFilter.push(['personId','like',this.criteria.personId+'%'])
      if (tmpFilter.length > 0) {
        this.searchFilter = {
          searchWhere : tmpFilter
        }
      } else {
        this.searchFilter = null
      }
    },
  }
}
</script>

<style lang="css">
</style>
