<template lang="html">
  <v-model-table model-name="Appointments" model-title="รายการนัดหมาย"
    :model-api="['models','Appointment','Appointments']"
    :model-api-search="searchQuery" :initial-data="initialData"
    :headers="headers" expandable
    v-if="hn">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="4">
            <v-master-field v-model="data.clinicCode" :custom-api="['models','Master','Clinics','query']"
            custom-api-text="clinicName" custom-api-value="clinicCode"
            :filter-text="{encounterType: 'AMB'}"
            :value-object.sync="currentClinic"
            customApiEagerLoad
            :rules="[rules.require()]"
            label="คลินิก" show-code></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-master-field v-model="data.doctorCode" :custom-api="['models','Master','Doctors','query']"
            custom-api-text="nameTH" custom-api-value="doctorCode"
            :filter-text="(currentClinic && currentClinic.specialty) ? {specialty: currentClinic.specialty} : undefined"
            customApiEagerLoad
            :rules="[rules.require()]"
            label="แพทย์" show-code></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-master-field :rules="[rules.require()]" v-model="data.appointmentType" label="ชนิดนัด" :groupKey="$store.getters.getGroupKey('appointmentType')"></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-date-time-field :rules="[rules.require(),ruleOnlyFutureDate(data.appointmentDateTime)]" v-model="data.appointmentDateTime" append-icon="event" label="วัน-เวลานัดหมาย"></v-date-time-field>
          </v-col>
          <v-col cols="4">
            <v-text-field :rules="[rules.require()]" v-model="data.appointmentDuration" suffix="นาที" label="ระยะเวลา"></v-text-field>
            <v-hidden-field v-model="data.appointmentDuration" :item-value="currentClinic.defaultTimeSlot" v-if="currentClinic && !data.appointmentDuration"></v-hidden-field>
          </v-col>
          <v-col cols="4">
            <v-master-field v-model="data.appointmentActivity" label="กิจกรรม" :groupKey="$store.getters.getGroupKey('appointmentActivity')"></v-master-field>
          </v-col>
          <v-col cols="6">
            <v-textarea v-model="data.suggestion" label="คำแนะนำ" auto-grow></v-textarea>
          </v-col>
          <v-col cols="6">
            <v-textarea v-model="data.note" label="บันทึกภายใน" auto-grow></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-form-pad v-model="data.additionalDetail">
              <template v-slot="{data: addData, rules}">
                <v-tabs-group>
                  <template v-slot:tabs>
                    <v-tab key="laboratory">รายการห้องปฏิบัติการ</v-tab>
                    <v-tab key="radiology">รายการตรวจทางรังสีวิทยา</v-tab>
                  </template>
                  <template v-slot:items>
                    <v-tab-item key="laboratory" eager>
                      <v-table-field v-model="addData.laboratory" title="รายการส่งตรวจทางห้องปฏิบัติการ" :headers="labHeaders" hide-toolbar show-create-pad auto-item-action>
                        <template v-slot:createPad="{data: labData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="12">
                                <v-master-field :rules="[rules.require()]" v-model="labData.productCode" 
                                  :custom-api="['models','Master','Products','query']" 
                                  custom-api-text="productName" 
                                  custom-api-value="productCode"
                                  :filter-text="{productType: 'laboratory'}"
                                  label="รายการตรวจ" show-code customApiEagerLoad></v-master-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <template v-slot:item.productCode="props">
                          <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                    <v-tab-item key="radiology" eager>
                      <v-table-field v-model="addData.radiology" title="รายการส่งตรวจทางรังสีวิทยา" :headers="xrayHeaders" hide-toolbar show-create-pad auto-item-action>
                        <template v-slot:createPad="{data: xrayData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="12">
                                <v-master-field :rules="[rules.require()]" v-model="xrayData.productCode" 
                                  :custom-api="['models','Master','Products','query']" 
                                  custom-api-text="productName" 
                                  custom-api-value="productCode"
                                  :filter-text="{productType: 'radiology'}"
                                  label="รายการตรวจ" show-code customApiEagerLoad></v-master-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <template v-slot:item.productCode="props">
                          <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                  </template>
                </v-tabs-group>
              </template>
            </v-form-pad>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.appointmentDateTime="props">
      <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
    </template>
    <template v-slot:item.appointmentType="props">
      <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
    </template>
    <template v-slot:item.appointmentActivity="props">
      <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="printAppointment(props.item)">print</v-icon>
      <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)" :disabled="props.item.to_encounter && props.item.to_encounter.length>0">$vuetify.icons.edit</v-icon>
      <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)"  :disabled="props.item.to_encounter && props.item.to_encounter.length>0">$vuetify.icons.delete</v-icon>
    </template>
    <template v-slot:expanded-item="{ headers,item,actions }">
      <td :colspan="headers.length" class="py-0">
        <v-row>
          <v-col cols="12" sm="4" md="2" class="font-weight-bold">คำแนะนำ</v-col>
          <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.suggestion || "-" }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="2" class="font-weight-bold">บันทึกภายใน</v-col>
          <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.note || "-" }}</v-col>
        </v-row>
        <v-row v-if="item.from_encounter">
          <v-col cols="12" class="font-weight-bold">ประวัติการรักษาครั้งก่อนหน้า</v-col>
          <v-col cols="12">
            <v-document-list :hn="item.from_encounter.hn" :encounterId="item.from_encounter.encounterId" folder="default" flat></v-document-list>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { isUndefined,find } from 'lodash'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    headers: [
      {
        text: 'เวลานัดหมาย',
        value: 'appointmentDateTime',
        class: 'body-2',
      },
      {
        text: 'ระยะเวลา (นาที)',
        value: 'appointmentDuration',
        class: 'body-2',
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2',
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'ชนิดการนัด',
        value: 'appointmentType',
        class: 'body-2'
      },
      {
        text: 'กิจกรรม',
        value: 'appointmentActivity',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    labHeaders: [
      {
        text: 'รายการตรวจ',
        value: 'productCode',
        class: 'body-2',
      },
    ],
    xrayHeaders: [
      {
        text: 'รายการตรวจ',
        value: 'productCode',
        class: 'body-2',
      },
    ],
    currentClinic: undefined,
    currentAdditionalDetailTab: undefined,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    clinicCode: {
      type: String,
      default: undefined
    },
    doctorCode: {
      type: String,
      default: undefined
    }
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
        fromEncounterId: this.encounterId,
        clinicCode: this.clinicCode,
        doctorCode: this.doctorCode,
        additionalDetail: {
          laboratory: [],
          radiology: []
        }
      }
    },
    searchQuery() {
      return {
        hn: this.hn,
        'whereDate#appointmentDateTime#>=': helperDateTime.today().format('YYYY-MM-DD')
      }
    },
  },
  methods: {
    ruleOnlyFutureDate(value) {
      return v => !v || helperDateTime.parseDateTime(value).isAfter(helperDateTime.today()) || 'Should be after now'
    },
    printAppointment(appointmentData) {
      this.printTemporary(this.hn,undefined,'appointment-card-th',appointmentData);
    }
  },
}
</script>

<style lang="css">
</style>
