import api from 'tantee-common/lib/api'
import helperPatient from '@/modules/helperPatient'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import { isUndefined,isArray } from 'lodash'

export default {
  methods: {
    printLabel: async function(labelTemplate,encounter,labelCustomData=undefined,labelCopies=1) {
      let patientName = await helperPatient.name(encounter.patient.name_th)
      let printPromise = new Promise((resolve,reject)=>{
        try {
          let labelPatientData = {
            "Name": patientName,
            "HN": encounter.patient.hn,
            "EncounterId": encounter.encounterId,
            "HnCode39Barcode": encounter.patient.hn,
            "HnCode128Barcode": encounter.patient.hn,
            "HnQrCodeBarcode": encounter.patient.hn,
            "Age": 'อายุ '+encounter.patient.age,
            "DOB": helperDateTime.toShortDate(encounter.patient.dateOfBirth),
            "DOBShort": helperDateTime.toTinyDate(encounter.patient.dateOfBirth),
            "Location": encounter.location.locationName + ((!isUndefined(find(encounter.location.subunits,{'subunitCode': encounter.locationSubunitCode}))) ? ' '+find(encounter.location.subunits,{'subunitCode': encounter.locationSubunitCode}).subunitName : '')
          }
          let labelData = {
            printData: [],
            printTemplate: labelTemplate,
            printCopies: labelCopies
          }
          if (isUndefined(labelCustomData)) {
            labelData.printData.push(labelPatientData)
          } else {
            if (isArray(labelCustomData)) {
              labelCustomData.forEach((row)=>{
                labelData.printData.push(Object.assign({}, row, labelPatientData))
              })
            } else {
              labelData.printData.push(Object.assign({}, labelCustomData, labelPatientData))
            }
          }
          api.post(['localDevice', 'Label', 'print'], labelData, {}, false, false)
          .catch((e) => reject(e))
          .finally(()=> resolve())
        } catch (e) {
          reject(e)
        }
      })
      this.$loading('Printing label', printPromise)
    },
  }
}