export let moduleMenu = [
  {
    title: 'Radiology Department',
    action: 'mdi-radioactive',
    menuItems: [
      {
        title: 'Diagnostic room',
        action: 'mdi-camera',
        link: { name: 'radiology.diagnostic' },
        permission: 'radiology-diagnostic',
      },
      {
        title: 'LINAC room',
        action: 'mdi-flash',
        link: { name: 'radiology.linac' },
        permission: 'radiology-linac',
      },
      {
        title: 'Reporting room',
        action: 'mdi-file-document-edit-outline',
        link: { name: 'radiology.reporting' },
        permission: 'radiology-reporting',
      },
    ],
    permission: 'radiology',
  },
]

export let moduleRoute = [
  {
    path: '/radiology/DiagnosticRoom',
    name: 'radiology.diagnostic',
    component: () => import('./DiagnosticRoom')
  },
  {
    path: '/radiology/LinacRoom',
    name: 'radiology.linac',
    component: () => import('./LinacRoom')
  },
  {
    path: '/radiology/ReportingRoom/:radiologyId?',
    name: 'radiology.reporting',
    component: () => import('./ReportingRoom'),
    props: true,
  },
]