<template lang="html">
  <v-form-pad ref="prescriptionItem" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="10">
            <v-master-field dense :rules="[rules.require()]" v-model="data.productCode" 
              :custom-api="['models','Master','Products','query']"
              custom-api-scope="stockable"
              custom-api-text="productName" 
              custom-api-value="productCode" 
              :value-object.sync="currentProduct"
              label="รายการ" show-code
            ></v-master-field>
            <v-hidden-field v-model="data.directions" :item-value="currentProduct" :hook="defaultProductDirection" v-if="currentProduct"></v-hidden-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="data.quantity"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-for="(direction,index) of data.directions" :key="index" dense>
          <v-col cols="3">
            <v-master-field dense v-model="direction.route" label="วิธีใช้"
              :showOnly="data.allowedRoute"
              groupKey="medicineRoute"
              clearable
              :rules="[rules.require()]"
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-text-field dense v-model="direction.amount" :rules="[rules.numeric()]" label="ปริมาณ"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-master-field dense v-model="direction.unit" label="หน่วย"
              groupKey="unit"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-master-field dense v-model="direction.frequency" label="ความถี่"
              groupKey="medicineFrequency"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-master-field dense v-model="direction.times" label="เวลา"
              groupKey="medicineTimes"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="data.directions.splice(index,1)" v-if="index>0"><v-icon>delete</v-icon></v-btn>
            <v-btn icon @click="data.directions.push({})" v-if="index==data.directions.length-1"><v-icon>add_circle</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-master-field dense v-model="data.cautions" label="ข้อควรระวัง"
              groupKey="medicineCaution"
              clearable multiple
            ></v-master-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
import {isEqual} from 'lodash'

export default {
  mixins: [subForm],
  data: ()=>({
    initialData: {
      directions: [{}],
    },
    currentProduct: undefined,
  }),
  methods: {
    defaultProductDirection(itemValue,value) {
      if (isEqual(value,this.initialData.directions) && (itemValue.specification && (itemValue.specification.defaultRoute || itemValue.specification.defaultAmount || itemValue.specification.defaultUnit || itemValue.specification.defaultFrequency || itemValue.specification.defaultTimes))) {
        return [
          {
            route: itemValue.specification.defaultRoute,
            amount: itemValue.specification.defaultAmount,
            unit: itemValue.specification.defaultUnit,
            frequency: itemValue.specification.defaultFrequency,
            times: itemValue.specification.defaultTimes
          }
        ]
      } else {
        return value
      }
    }
  }
}
</script>

<style lang="css">
</style>
