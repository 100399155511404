export let moduleMenu = [
  {
    title: 'Medical Record',
    action: 'mdi-folder-text-outline',
    menuItems: [
      {
        title: 'Medical Record',
        action: 'mdi-folder-text-outline',
        link: { name: 'medicalrecord.medicalrecord' },
        permission: 'medicalrecord-medicalrecord',
      },
      {
        title: 'Scanned Documents',
        action: 'scanner',
        link: { name: 'medicalrecord.scanneddocuments' },
        permission: 'medicalrecord-scanneddocuments',
      },
      {
        title: 'Encounter Coder',
        action: 'mdi-book-information-variant',
        link: { name: 'medicalrecord.encountercoder' },
        permission: 'medicalrecord-encountercoder',
      },
    ],
    permission: 'registration',
  },
]

export let moduleRoute = [
  {
    path: '/medicalrecord/MedicalRecord',
    name: 'medicalrecord.medicalrecord',
    component: () => import('./MedicalRecord')
  },
  {
    path: '/medicalrecord/ScannedDocuments',
    name: 'medicalrecord.scanneddocuments',
    component: () => import('./ScannedDocuments')
  },
  {
    path: '/medicalrecord/EncounterCoder',
    name: 'medicalrecord.encountercoder',
    component: () => import('./EncounterCoder')
  },
]