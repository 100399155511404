<template lang="html">
  <div :style="parentStyle" v-if="!avatar">
    <div :style="childStyle">
      <v-img :src="imgSrc" :width="imgWidth" :height="imgHeight"
        class="force-print-background"
        contain
        v-if="imgSrc"
      ></v-img>
      <v-icon :size="imgWidth" v-if="!imgSrc">person</v-icon>
    </div>
  </div>
  <v-menu v-else
    :nudge-right="20"
    transition="scale-transition"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-avatar :size="avatarSize" v-on="on" style="cursor:pointer">
        <v-img :src="imgSrc" v-if="imgSrc"></v-img>
        <v-icon :size="avatarSize" v-else>person</v-icon>
      </v-avatar>
    </template>
    <v-img max-width="600" :src="imgSrc" v-if="imgSrc"></v-img>
  </v-menu>
</template>

<script>
import api from 'tantee-common/lib/api'
import { isUndefined } from 'lodash'
import { Promise } from 'q';

export default {
  data: () => ({
    imgData: undefined
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    width: {
      type: Number,
      default: 150
    },
    height: {
      type: Number,
      default: 180
    },
    aspectRatio: {
      type: Number,
      default: 150/180
    },
    border: {
      type: [Boolean, Number],
      default: 1
    },
    avatar: {
      type: Boolean,
      default: false
    },
    avatarSize: {
      type: [Number, String],
      default: 48
    }
  },
  methods: {
    getPatientPhoto(hn) {
      if (!isUndefined(hn) && (hn!=null) && (hn.length>0)) {
        api.get(['Patient','PatientPhotoController','getPhoto'],{hn: hn}).then((returnData)=>{
          if (returnData.success) {
            this.imgData = returnData.data.base64data
          }
        })
      }
    }
  },
  watch: {
    hn: function (newVal) {
      this.getPatientPhoto(newVal)
    },
  },
  mounted() {
    this.getPatientPhoto(this.hn)
  },
  computed: {
    parentStyle() {
      let returnStyle = 'display: table;overflow:hidden;width: '+this.imgWidth+'px; height: '+this.imgHeight+'px;max-width: '+this.imgWidth+'px; max-height: '+this.imgHeight+'px;'
      if (Number(this.border)>0) {
        returnStyle = returnStyle+'border: solid '+Number(this.border)+'px #000000;'
      }
      return returnStyle
    },
    childStyle() {
      let returnStyle = 'overflow:hidden;width: '+this.imgWidth+'px; height: '+this.imgHeight+'px;max-width: '+this.imgWidth+'px; max-height: '+this.imgHeight+'px;'
      returnStyle = returnStyle+'display: table-cell;vertical-align: middle;'
      return returnStyle
    },
    imgSrc() {
      if (isUndefined(this.imgData)) {
        return undefined
      } else {
        return this.imgData
      }
    },
    imgWidth() {
      if (this.width>(this.aspectRatio*this.height)) {
        return parseInt(this.aspectRatio*this.height)
      } else {
        return this.width
      }
    },
    imgHeight() {
      if (this.height>(this.width/this.aspectRatio)) {
        return parseInt(this.width/this.aspectRatio)
      } else {
        return this.height
      }
    }
  },
}
</script>

<style lang="css">
.force-print-background {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}
</style>
