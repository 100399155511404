<template lang="html">
  <v-text-field v-bind:value="value" v-bind="$attrs" v-on="$listeners"
    label="Encounter ID"
    append-outer-icon="search"
    @click:append-outer="dialogSearchEncounter"
    @keydown.114.prevent="dialogSearchEncounter"
    @keydown.enter.prevent="checkEncounter"
    @blur="checkEncounter"
    :messages="currentEncounterDetail"
    v-model="currentEncounterInput"
    :loading="isLoadingCombined" :disabled="isDisabledCombined"
    hide-details
  ></v-text-field>
</template>

<script>
import helperEncounter from '@/modules/helperEncounter'
import isUndefined from 'lodash'

export default {
  data: ()=>({
    currentEncounter: undefined,
    currentEncounterInput: null,
    isLoading: false,
  }),
  props: {
    value: {
      default: null
    },
    loadDetail: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    encounterType: {
      type: [String, Array],
      default: 'AMB'
    }
  },
  watch: {
    value: function(newValue) {
      if (this.currentEncounterInput!=newValue) {
        this.currentEncounterInput = newValue
        this.checkEncounter()
      }
    },
  },
  methods: {
    dialogSearchEncounter() {
      this.$encounter([['encounterType','=',this.encounterType]]).then((res)=>{
        if (res!==false) {
          this.$emit('input',res)
          if (this.loadDetail) {
            this.$nextTick(()=>{
              this.checkEncounter()
            })
          } else {
            this.$emit('encounterChange',res)
          }
        }
      })
    },
    checkEncounter() {
      if ((this.currentEncounterInput !== null) && (isUndefined(this.currentEncounter) || this.currentEncounterInput !== this.currentEncounter.encounterId) && (!this.isLoading)) {
        this.isLoading = true
        helperEncounter.getEncounterById(this.currentEncounterInput).then((returnData)=>{
          this.currentEncounter = returnData
          this.$emit('encounterChange',returnData.encounterId)
        }).catch((error)=>{
          this.reset()
        }).finally(()=>{
          this.isLoading = false
        })
      } else {
        this.reset()
      }
    },
    reset() {
      this.currentEncounter = undefined
      this.currentEncounterInput = null
      this.$emit('input',undefined)
      this.$emit('encounterChange',undefined)
    }
  },
  computed: {
    isLoadingCombined() {
      return this.isLoading || this.loading
    },
    isDisabledCombined() {
      return this.isLoading || this.disabled
    },
    currentEncounterDetail() {
      if (!isUndefined(this.currentEncounter)) return this.currentEncounter.hn+' @ '+this.currentEncounter.clinic.clinicName
      else return null
    }
  },
  mounted() {
    if (this.currentEncounterInput!=this.value) {
      this.currentEncounterInput=this.value
      this.checkEncounter()
    }
  }
}
</script>

<style lang="css">
</style>
