<template lang="html">
  <v-model-table model-name="Relatives" model-title="Relatives"
    :model-api="['models','Patient','PatientsRelatives']"
    :model-api-search="{'hn': hn}" :initial-data="{'hn': hn}"
    :headers="headers" v-if="hn" v-bind="$attrs" v-on="$listeners">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-relative v-model="data"></v-relative>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { find } from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'ชื่อผู้ติดต่อ',
        value: 'name',
        class: 'body-2',
      },
      {
        text: 'ความเกี่ยวข้อง',
        value: 'relation',
        class: 'body-2'
      },
      {
        text: 'เบอร์โทรศัพท์มือถือ',
        value: 'mobileNo',
        class: 'body-2'
      },
      {
        text: 'เบอร์โทรศัพท์บ้าน',
        value: 'telephoneNo',
        class: 'body-2'
      },
      {
        text: 'Email',
        value: 'email',
        class: 'body-2'
      },
      {
        text: 'หมายเหตุ',
        value: 'note',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
}
</script>

<style lang="css">
</style>
