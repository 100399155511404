<template lang="html">
  <v-form-pad ref="relativeForm" v-model="relativeData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="9">
            <v-text-field v-model="data.name" label="ชื่อผู้ติดต่อ"
              :disabled="disabled"
              :rules="[rules.requireIf(required && !disabled)]"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="data.relation" label="ความเกี่ยวข้อง"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="data.mobileNo" label="เบอร์โทรศัพท์มือถือ"
              :disabled="disabled"
              :rules="[rules.requireIf(required && !disabled)]"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="data.telephoneNo" label="เบอร์โทรศัพท์บ้าน"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="data.email" label="Email"
              :disabled="disabled"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import { isUndefined } from 'lodash'
import Vue from 'vue'

export default {
  data: ()=>({
    relativeData: {},
  }),
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler() {
        this.loadRelative()
      },
      deep: true
    },
    relativeData: {
      handler(newVal) {
        this.$emit("input",newVal)
      },
      deep: true
    },
  },
  methods: {
    loadRelative() {
      this.relativeData = this.value
    },
  },
  mounted() {
    this.loadRelative()
  },
}
</script>

<style lang="css">
</style>
