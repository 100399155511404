import helperEncounter from '@/modules/helperEncounter'
import {isUndefined,cloneDeep} from 'lodash'

export default {
  data: ()=>({
    currentEncounter: undefined,
  }),
  props: {
    encounterId: {
      type: String,
      default: undefined
    },
    encounter: {
      type: Object,
      default: undefined
    },
  },
  watch: {
    encounterId: function(newVal) {
      this.loadEncounter(newVal)
    },
    encounter: {
      handler() {
        this.currentEncounter = cloneDeep(this.encounter)
        if (isUndefined(this.currentEncounter)) this.$emit('encounterLoaded', this.currentEncounter)
      },
      deep: true
    },
  },
  methods: {
    loadEncounter(encounterId) {
      helperEncounter.getEncounterById(encounterId).then((returnData)=>{
        this.currentEncounter = returnData
        this.$emit('encounterLoaded', returnData)
        this.$forceUpdate()
      }).catch((error)=>{
        this.reset()
      })
    },
    reset() {
      this.currentEncounter = undefined
      this.$emit('encounterUnloaded')
    },
    onEncounterLoaded(encounter) {
      this.currentEncounter = encounter
      this.$emit('encounterLoaded', encounter)
    },
    onEncounterUnloaded() {
      this.reset()
    }
  },
  mounted() {
    if (!isUndefined(this.encounterId)) this.loadEncounter(this.encounterId)
    if (!isUndefined(this.encounter)) this.currentEncounter = cloneDeep(this.encounter)
  }
}
