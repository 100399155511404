<template lang="html">
  <v-model-table model-name="DocumentsTemplates" 
    :model-api="['models','Document','DocumentsTemplates']"
    :model-api-search="documentFilter" 
    :headers="headers" 
    :insertable="false" searchable serverPagination>
    <template v-slot:toolBarItem>
      <v-master-field v-model="filterCriteria.defaultCategory" label="หมวดหมู่เอกสาร"
        :groupKey="$store.getters.getGroupKey('documentCategory')"
        :showExcept="(showIpd) ? undefined : IPDDocumentCategory"
        single-line hide-details
        clearable
        show-code
      ></v-master-field>
    </template>
    <template v-slot:item.templateCode="props">
      {{ props.item.templateCode }} (REV {{ props.item.revisionId }})
    </template>
    <template v-slot:item.defaultCategory="props">
      <v-label-master group-key="documentCategory" :item-code="props.item.defaultCategory"></v-label-master>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="printDocument(props.item)" v-if="hn">print</v-icon>
    </template>
  </v-model-table>
</template>

<script>
import api from 'tantee-common/lib/api'
import {isUndefined,range} from 'lodash'

export default {
  data: ()=>({
    IPDDocumentCategory: ['300','301','302','303','304','305','310','311','320'],
    headers: [
      {
        text: 'Template ID',
        value: 'templateCode',
        class: 'body-2'
      },
      {
        text: 'Template Name',
        value: 'templateName',
        class: 'body-2'
      },
      {
        text: 'Default Category',
        value: 'defaultCategory',
        class: 'body-2'
      },
      {
        text: 'Description',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    filterCriteria: {},
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    showIpd: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    documentFilter() {
      let filter = {isPrintable: true}
      if (isUndefined(this.hn)) filter.isRequiredPatientInfo = false
      if (isUndefined(this.encounterId)) filter.isRequiredEncounter = false
      if (!isUndefined(this.filterCriteria.defaultCategory)) filter.defaultCategory = this.filterCriteria.defaultCategory

      if (!this.showIpd) filter['whereNotIn#defaultCategory'] = this.IPDDocumentCategory

      return filter
    }
  },
  methods: {
    printDocument(template) {
      let printData = {
        hn: this.hn,
        encounterId: this.encounterId,
        documentTemplate: template.templateCode
      }
      let printPromise = api.post(['Document','PrintController','printBlankDocument'],printData).then((returnData)=>{
        this.$printPdfBase64(returnData['data'][0])
      }).catch((e)=>void e)
      this.$loading('Printing',printPromise)
    }
  }
}
</script>

<style lang="css">
</style>
