<template lang="html">
  <v-form-pad ref="productRadiology" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.icd9cm" label="ICD-9-CM Code"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.defaultFacility" label="Default facility"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.modality" label="Modality"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.bodypart" label="Body part"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
