const api = {
  default: {
    url: '/api',
  },
  localDevice: {
    url: 'http://localhost:1234',
  },
  pacs: {
    url: 'https://pacs.canceralliance.co.th/dcm4chee-arc/aets/CAHPACS'
  },
  pacsViewer: {
    url: 'https://pacs.canceralliance.co.th'
  }
}

const locale = 'th'

export default {
    api,
    locale
}
