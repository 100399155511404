<template lang="html">
  <v-form-pad ref="productProcedure" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="12">
            <v-master-field v-model="data.icd9cm" label="ICD-9-CM Code"
              groupKey="$ICD9CM"
              show-code
              chips
              clearable
              multiple
            ></v-master-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="data.performerFee" :rules="[rules.numeric()]" label="Performer Fee" suffix="บาท"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-checkbox v-model="data.isNeedDoctorCode" label="ต้องการรหัสแพทย์"></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  data: ()=>({
    initialData: {
      isNeedDoctorCode: true
    }
  }),
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
