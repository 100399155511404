export let moduleMenu = [
  {
    title: 'Inventory Management',
    action: 'mdi-cube',
    menuItems: [
      {
        title: 'Stock',
        action: 'mdi-nutrition',
        link: { name: 'inventory.stock' },
        permission: 'inventory-administrator'
      },
      {
        title: 'Local Stock',
        action: 'mdi-nutrition',
        link: { name: 'inventory.localStock' },
      },
      {
        title: 'Stock Request',
        action: 'mdi-basket-fill',
        link: { name: 'inventory.stockrequest' },
      },
      {
        title: 'Stock Dispensing',
        action: 'mdi-basket-unfill',
        link: { name: 'inventory.stockdispensing' },
      },
    ],
    permission: 'inventory',
  },
]

export let moduleRoute = [
  {
    path: '/inventory/Stock/:stockId?',
    name: 'inventory.stock',
    component: () => import('./Stock'),
    props: true,
  },
  {
    path: '/inventory/LocalStock',
    name: 'inventory.localStock',
    component: () => import('./LocalStock'),
  },
  {
    path: '/inventory/StockRequest',
    name: 'inventory.stockrequest',
    component: () => import('./StockRequest'),
  },
  {
    path: '/inventory/StockDispensing',
    name: 'inventory.stockdispensing',
    component: () => import('./StockDispensing'),
  },
]