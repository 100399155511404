<template lang="html">
  <v-model-table model-name="StocksProducts" model-title="Stocks Products"
    :model-api="['models','Stock','StocksProducts']"
    :model-api-search="stockProductQuery"
    api-scope="nonZero"
    :headers="headers"
    :insertable="false"
    group-by="productCode"
    searchable
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:toolBarButton v-if="stockId && (stockId<=10000)">
      <v-btn class="mr-1" @click="printStocksProducts" color="primary">พิมพ์</v-btn>
      <v-btn @click="exportStocksProducts" color="primary"><v-icon>mdi-file-download</v-icon></v-btn>
    </template>
    <template v-slot:group.header="props">
      <td :colspan="props.headers.length-1">
        <label class="font-weight-bold">{{ props.group }} {{ props.items[0].product.productName }}</label>
      </td>
      <td colspan="1">
        <v-info-dialog form-title="รายการความเคลื่อนไหว">
          <template v-slot:activateButton="button">
            <v-icon v-on="button.on" :disabled="button.disabled">mdi-animation-play-outline</v-icon>
          </template>
          <template v-slot:content>
            <v-stocks-cards :stockId="props.items[0].stockId" :productCode="props.items[0].productCode" flat :insertable="false" exportable></v-stocks-cards>
          </template>
        </v-info-dialog>
      </td>
    </template>
  </v-model-table>
</template>

<script>
import helperItem from '@/modules/helperItem'
import printDocument from '@/components/mixins/printDocument'
import * as papa from 'papaparse'
import FileSaver from 'file-saver'

export default {
  mixins: [printDocument],
  data: ()=>({
    headers: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'ชื่อสินค้า',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'Lot No.',
        value: 'lotNo',
        class: 'body-2'
      },
      {
        text: 'Expiry Date',
        value: 'expiryDate',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
    ],
    cardType: [
      {text: 'รับเข้า', value: 'receive'},
      {text: 'จำหน่าย', value: 'dispensing'},
      {text: 'ย้ายคลัง', value: 'transfer'},
      {text: 'ปรับยอด', value: 'correction'},
      {text: 'รับคืน', value: 'return'},
    ],
  }),
  props: {
    stockId: {
      type: [String, Number],
      default: undefined,
    }
  },
  computed: {
    stockProductQuery() {
      return {
        stockId: this.stockId
      }
    }
  },
  methods: {
    printStocksProducts() {
      let printPromise = helperItem.findItem(['models','Stock','Stocks'],this.stockId,['StocksProducts']).then((returnData)=>{
        this.printTemporary(undefined,undefined,'report_stocksproducts',returnData);
      }).catch((e)=>{
        void e
      })

      this.$loading('Printing',printPromise)
    },
    exportStocksProducts() {
      let exportPromise = helperItem.findItem(['models','Stock','Stocks'],this.stockId,['StocksProducts']).then((returnData)=>{
        if (returnData.stocks_products) {
          let exportData = returnData.stocks_products.map((item)=>{
            return {
              stockId: item.stockId,
              stockName: returnData.stockName,
              productCode: item.productCode,
              productName: (item.product) ? item.product.productName : '',
              lotNo: item.lotNo,
              expiryDate: item.expiryDate,
              quantity: item.quantity,
              actual: ''
            }
          })

          let content = papa.unparse(exportData)
          var blob = new Blob([String.fromCharCode(0xFEFF),content], {type: "text/plain;charset=utf-8"});
          FileSaver.saveAs(blob, returnData.stockName + '.csv');
        }
        
      }).catch((e)=>{
        void e
      })

      this.$loading('Exporting',exportPromise)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
