<template lang="html">
  <v-card color="white">
    <v-card-text v-if="currentEncounter" class="pa-2">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="12" sm="2" md="1" align-self="center" class="text-center pa-0">
            <v-patient-photo :hn="currentEncounter.patient.hn" avatar avatar-size="80"></v-patient-photo>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col cols="12" sm="4" md="2" align-self="center" class="font-weight-bold display-1 text-truncate">
                {{ currentEncounter.patient.hn }}
              </v-col>
              <v-col align-self="center" class="display-1 text-truncate">
                <v-label-name :name-data="currentEncounter.patient.name_th"></v-label-name>
              </v-col>
              <v-col cols="12" sm="6" md="2" align-self="center" class="headline text-truncate">
                <v-label-age :date-of-birth="currentEncounter.patient.dateOfBirth"></v-label-age>
              </v-col>
              <v-col cols="12" sm="6" md="3" align-self="center" class="headline text-truncate">
                วันเกิด <v-label-datetime :date-time="currentEncounter.patient.dateOfBirth" short-date></v-label-datetime>
              </v-col>
              <v-col md="auto">
                <v-patient-messages-icon :hn="currentEncounter.hn"></v-patient-messages-icon>
                <v-patient-insurances-icon :hn="currentEncounter.hn" :clinicCode="currentEncounter.clinicCode"></v-patient-insurances-icon>
                <v-patient-allergies-icon :hn="currentEncounter.hn"></v-patient-allergies-icon>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="3" lg="2" align-self="center" class="headline text-truncate" v-if="$vuetify.breakpoint.mdAndUp">
                <template v-if="currentEncounter.encounterType=='AMB'">
                  <v-icon v-if="currentEncounter.fromAppointmentId" color="green darken-3">mdi-calendar-check</v-icon>
                  <v-icon v-else color="red">mdi-calendar-remove</v-icon>
                  {{ currentEncounter.encounterId.substr(0,currentEncounter.encounterId.length-3) }}<span class="font-weight-bold">{{ currentEncounter.encounterId.substr(-3) }}</span>
                </template>
                <template v-if="currentEncounter.encounterType=='IMP'">
                  <v-icon color="green darken-3">mdi-hotel</v-icon>
                  {{ currentEncounter.encounterId.substr(0,currentEncounter.encounterId.length-9) }}<span class="font-weight-bold">{{ currentEncounter.encounterId.substr(-9) }}</span>
                </template>
              </v-col>
              <v-col cols="12" md="3" align-self="center" class="headline text-truncate" v-if="currentEncounter.encounterType=='AMB'">
                <v-icon>mdi-calendar-plus</v-icon>
                <v-label-datetime :date-time="currentEncounter.admitDateTime" short-date></v-label-datetime>
              </v-col>
              <v-col cols="12" md="3" lg="4" align-self="center" class="headline text-truncate">
                <v-icon>mdi-hospital-box</v-icon>
                {{ currentEncounter.clinic.clinicName }}
                <template v-if="currentEncounter.encounterType=='IMP'">
                  <v-label-items :items="currentEncounter.location.subunits" item-key="subunitCode" item-value="subunitName" :value="currentEncounter.locationSubunitCode"></v-label-items>
                </template>
              </v-col>
              <v-col cols="12" md="3" align-self="center" class="headline text-truncate" v-if="currentEncounter.encounterType=='IMP'">
                <v-icon>mdi-hospital-building</v-icon>
                {{ currentEncounter.location.locationName }}
              </v-col>
              <v-col cols="12" md="3" align-self="center" class="headline text-truncate">
                <v-icon>mdi-stethoscope</v-icon>
                {{ currentEncounter.doctor.nameTH }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" align-self="center" class="text-right">
            <v-btn icon @click="reset" x-large><v-icon>close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-container v-else pt-0 fluid>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-encounter-select @encounterChange="searchEncounter" :encounterType="encounterType" :loading="isLoading" :disabled="isLoading"></v-encounter-select>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import helperEncounter from '@/modules/helperEncounter'
import { isUndefined } from 'lodash'

export default {
  data: () => ({
    currentEncounter: undefined,
    isLoading: false,
  }),
  props: {
    value: {
      type: String,
      default: undefined
    },
    encounterType: {
      type: [String, Array],
      default: 'AMB'
    }
  },
  watch: {
    value: function (newVal) {
      if (!isUndefined(newVal)) this.loadEncounter(newVal)
      else this.reset()
    }
  },
  methods: {
    searchEncounter(encounterId) {
      if (!isUndefined(encounterId) && encounterId.length>0) {
        this.isLoading = true
        this.loadEncounter(encounterId).then((returnData)=>{
          this.$emit('input',returnData.encounterId)
        }).catch(()=>{
          this.reset()
        }).finally(()=>{
          this.isLoading = false
        })
      } else {
        this.reset()
      }
    },
    loadEncounter(encounterId) {
      return new Promise((resolve,reject)=>{
        helperEncounter.getEncounterById(encounterId).then((returnData)=>{
          this.currentEncounter = returnData
          resolve(returnData)
          this.$emit('encounterLoaded',returnData)
        }).catch((error)=>{
          reject(error)
          this.reset()
        })
      })
    },
    reset() {
      this.$emit('input',undefined)
      this.$emit('encounterUnloaded')
      this.currentEncounter = undefined
      this.isLoading = false
    }
  },
  beforeMount() {
    if (!isUndefined(this.value) && this.value!==null) this.loadEncounter(this.value)
  },
}
</script>

<style lang="css">
</style>
