<template lang="html">
  <v-action-dialog
    ActivateButtonText="Discharge" 
    formTitle="Discharge detail" 
    @saveData="discharge" 
    maxWidth="40vw" 
    :object="currentEncounter"
    v-if="!!currentEncounter && !currentEncounter.dischargeDateTime"
  >
    <template v-slot:activateButton="{on}">
      <v-btn color="primary" v-on="on"><v-icon>mdi-location-exit</v-icon>Discharge</v-btn>
    </template>
    <template v-slot="{data,rules}">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-date-time-field :rules="[rules.require()]" v-model="data.dischargeDateTime" :default-date-time="true"></v-date-time-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-action-dialog>
  <v-btn v-else-if="!!currentEncounter && !!currentEncounter.dischargeDateTime" color="primary" @click="cancelDischarge">
    <v-icon>mdi-restore</v-icon>
    Cancel Discharge
  </v-btn>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'
import apiCRUD from '@/components/mixins/apiCRUD'
import {isUndefined,cloneDeep} from 'lodash'

export default {
  mixins: [encounterLoader,apiCRUD],
  methods: {
    discharge(formData,callback=undefined) {
      this.updateAPI('encounters',formData,'encounterId').then((returnData)=>{
        this.currentEncounter = returnData.data[0]
        if (!isUndefined(callback)) callback.done()
        this.$emit('encounterDischarged', returnData.data)
      }).catch((errors) => {
        if (!isUndefined(callback)) callback.error(errors)
      })
    },
    cancelDischarge() {
      this.$confirm('ต้องการที่จะยกเลิกการ Discharge').then((res)=>{
        if (res) {
          let tmpEncounter = cloneDeep(this.currentEncounter)
          tmpEncounter.dischargeDateTime = null
          this.updateAPI('encounters',tmpEncounter,'encounterId').then((returnData)=>{
            this.currentEncounter = returnData.data[0]
            this.$emit('encounterCancelDischarged', returnData.data)
          }).catch((errors) => {
            void errors
          })
        }
      })
    }
  },
  mounted() {
    this.apiCustomUrl.update = ['models','Registration','Encounters','update']
  }
}
</script>

<style lang="css" scoped>
</style>
