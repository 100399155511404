import Store from '@/store'
import api from 'tantee-common/lib/api'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import * as helper from '@/modules/helperPatient'
import { isUndefined,isNull } from 'lodash'

function prefix(string, prefix) {
  if (string && !string.startsWith(prefix)) string = prefix +' '+string

  return string
}

export async function name(nameData,shortName=false) {
  let namePrefixText = null
  let nameSuffixText = null
  if (nameData.nameType=='TH' || nameData.nameType=='ALIAS_TH') {
    namePrefixText = await Store.getters.getItemValue(Store.getters.getGroupKey('namePrefix'),nameData.namePrefix)
  } else {
    namePrefixText = await Store.getters.getItemValue(Store.getters.getGroupKey('namePrefix'),nameData.namePrefix,true)
  }

  if (!shortName) {
    if (nameData.nameType=='TH' || nameData.nameType=='ALIAS_TH') {
      nameSuffixText = await Store.getters.getItemValue(Store.getters.getGroupKey('nameSuffix'),nameData.nameSuffix)
    } else {
      nameSuffixText = await Store.getters.getItemValue(Store.getters.getGroupKey('nameSuffix'),nameData.nameSuffix,true)
    }
  }

  if (!shortName) return [namePrefixText,nameData.firstName,nameData.middleName,nameData.lastName,nameSuffixText].join(' ')
  else return [namePrefixText,nameData.firstName].join(' ')
}

export async function address(addressData) {
  let addressText = []
  if (!isUndefined(addressData.address) && !isNull(addressData.address)) addressText.push(addressData.address)

  if (addressData.country == 'TH') {
    if (!isUndefined(addressData.village) && !isNull(addressData.village)) addressText.push(prefix(addressData.village,'หมู่บ้าน/คอนโด'))
    if (!isUndefined(addressData.moo) && !isNull(addressData.moo)) addressText.push(prefix(addressData.moo,'หมู่ที่'))
    if (!isUndefined(addressData.trok) && !isNull(addressData.trok)) addressText.push(prefix(addressData.trok,'ตรอก'))
    if (!isUndefined(addressData.soi) && !isNull(addressData.soi)) addressText.push(prefix(addressData.soi,'ซอย'))
    if (!isUndefined(addressData.street) && !isNull(addressData.street)) addressText.push(prefix(addressData.street,'ถนน'))
    if (!isUndefined(addressData.subdistrict) && !isNull(addressData.subdistrict)) addressText.push('ตำบล/แขวง '+ await Store.getters.getItemValue(Store.getters.getGroupKey('addressSubdistrict'),addressData.subdistrict))
    if (!isUndefined(addressData.district) && !isNull(addressData.district)) addressText.push('อำเภอ/เขต '+ await Store.getters.getItemValue(Store.getters.getGroupKey('addressDistrict'),addressData.district))
    if (!isUndefined(addressData.province) && !isNull(addressData.province)) addressText.push('จังหวัด '+ await Store.getters.getItemValue(Store.getters.getGroupKey('addressProvince'),addressData.province))
  } else {
    if (!isUndefined(addressData.street) && !isNull(addressData.street)) addressText.push(addressData.street)
    if (!isUndefined(addressData.subdistrict) && !isNull(addressData.subdistrict)) addressText.push(addressData.subdistrict)
    if (!isUndefined(addressData.district) && !isNull(addressData.district)) addressText.push(addressData.district)
    if (!isUndefined(addressData.province) && !isNull(addressData.province)) addressText.push(addressData.province)
    if (!isUndefined(addressData.country) && !isNull(addressData.country)) addressText.push(await Store.getters.getItemValue(Store.getters.getGroupKey('addressCountry'),addressData.country,true))
  }

  if (!isUndefined(addressData.postCode) && !isNull(addressData.postCode)) addressText.push(addressData.postCode)

  return addressText.join(' ')
}

export function age(dateOfBirth,years=true,months=true,days=false) {
  let tmpAge = helperDateTime.diff(dateOfBirth)
  let tmpAgeText = []

  if (tmpAge.asMonths() < 1) {
    days = true
    months = false
    years = false
  }
  if (tmpAge.asYears() < 1) {
    if (!days) months = true
    years = false
  }

  if (days && years) months = true
  if (years) tmpAgeText.push((months) ? tmpAge.years()+' ปี' : tmpAge.asYears().toFixed(2)+' ปี')
  if (months && !days) tmpAgeText.push((years) ? tmpAge.months()+' เดือน' : tmpAge.asMonths().toFixed(2)+' เดือน')
  if (months && days) tmpAgeText.push((years) ? tmpAge.months()+' เดือน' :tmpAge.asMonths().toFixed(0)+' เดือน')
  if (days) tmpAgeText.push((months) ? tmpAge.days()+' วัน' : tmpAge.asDays().toFixed(2)+' วัน')

  return tmpAgeText.join(' ')
}

export function getPatientByHn(HN) {
  return new Promise((resolve,reject)=>{
    if (!isUndefined(HN) && HN.length>0) {

      api.get(['Patient','PatientController','getPatient'],{hn: HN}).then((returnData)=>{
        if (returnData.success) {
          resolve(returnData.data)
        } else {
          reject(returnData.errorTexts)
        }
      }).catch((error)=>{
        reject(error)
      })
    } else {
      reject('Invalid HN input')
    }
  })
}

export default helper
