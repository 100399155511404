<template lang="html">
  <v-card :flat="flat">
    <v-toolbar flat>
      <v-toolbar-title>
        {{ title }}
        <v-btn text icon color="grey lighten-1" @click="loadData" v-if="refreshable">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-toolbar-items>

      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-file-btn class="mr-1" color="primary" @fileSelected="onImportPDF" :loading="isImporting" accept="application/pdf" v-if="insertable"><v-icon>mdi-pdf-box</v-icon></v-file-btn>&nbsp;
      <v-form-dialog :max-width="dialogWidth" :max-height="dialogHeight" :fullscreen="dialogFullscreen"
        :form-title="title" :initialData="metaData"
        @create="onCreateData"
        v-if="insertable"
      >
        <template v-slot="{data,rules,isCreating}">
          <v-scan-pad v-model="data.documentData" :available-category="availableCategory"></v-scan-pad>
        </template>
      </v-form-dialog>
    </v-toolbar>
    <v-card-text v-if="!isLoading">
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" sm="6" md="4" lg="2" v-for="(documentItem,index) in apiData[modelName]" :key="index">
            <v-document-scan-item :document="documentItem" @delete="deleteData"></v-document-scan-item>
          </v-col>
          <v-col cols="12" v-if="!hasDocument">
            <div align="center">
              <span class="headline">No documents</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-if="isLoading">
      <v-container fluid class="text-center">
        <v-row>
          <v-col cols="12">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="hasPagination">
      <v-spacer></v-spacer>
      <v-pagination :length="totalPages" v-model="currentPage"></v-pagination>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiCRUD from '@/components/mixins/apiCRUD'
import {isUndefined} from 'lodash'

export default {
  mixins: [apiCRUD],
  data: ()=>({
    totalPages: undefined,
    currentPage: 1,
    isLoading: false,
    initialData: {
      category: '',
    },
    isImporting: false,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    defaultCategory: {
      type: String,
      default: undefined
    },
    availableCategory: {
      type: Array,
      default: undefined
    },
    folder: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    referenceId:{
      type: String,
      default: undefined
    },
    perPage: {
      type: Number,
      default: 18
    },
    flat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Documents',
    },
    modelName: {
      type: String,
      default: 'documentData'
    },
    insertable: {
      type: Boolean,
      default: true
    },
    refreshable: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: '80vw'
    },
    dialogHeight: {
      type: String,
      default: '80vh'
    },
    dialogFullscreen: {
      type: Boolean,
      default: false
    },
    apiWith: {
      type: [String, Array],
      default: ()=>['encounter','template']
    }
  },
  methods: {
    reset() {
      this.totalPages = isUndefined
      this.currentPage = 1
    },
    onCreateData(formData,callback=undefined) {
      this.createAPI(this.modelName,formData).then(()=>{
        if (!isUndefined(callback)) callback.done()
      }).catch((errors) => {
        if (!isUndefined(callback)) callback.error(errors)
      })
    },
    onImportPDF(files) {
      this.isImporting = true
      if (files.length>0) {
        Array.from(files).forEach((file)=>{
          var reader = new FileReader()
          reader.onload = () => {
            let importData = {
              documentData: []
            }
            importData.documentData.push({
              base64string: reader.result
            })
            importData = Object.assign(importData,this.metaData)
            this.onCreateData(importData)
          }
          reader.readAsDataURL(file)
        })
      }
      this.isImporting = false
    },
    deleteData(object) {
      this.$confirm('ต้องการที่จะลบเอกสารของผู้ป่วย').then((res)=>{
        if (res) {
          this.deleteAPI(this.modelName,object)
        }
      })
    },
    loadData() {
      this.isLoading = true;

      let searchMeta = {
        status: "approved",
        isScanned: true
      }
      if (!isUndefined(this.hn)) searchMeta['hn'] = this.hn
      
      if (!isUndefined(this.availableCategory)) searchMeta['category'] = this.availableCategory
      else if (!isUndefined(this.defaultCategory)) searchMeta['category'] = this.defaultCategory

      if (!isUndefined(this.folder)) searchMeta['folder'] = this.folder
      if (!isUndefined(this.encounterId)) searchMeta['encounterId'] = this.encounterId
      if (!isUndefined(this.referenceId)) searchMeta['referenceId'] = this.referenceId

      this.searchAPI(this.modelName,searchMeta,this.perPage,this.currentPage).then((returnData)=>{
        if (returnData.meta && returnData.meta.last_page) this.totalPages = returnData.meta.last_page
        if (returnData.meta && returnData.meta.current_page) this.currentPage = returnData.meta.current_page
      }).finally(()=>{
        this.isLoading = false
      })
    }
  },
  computed: {
    hasPagination() {
      if (isUndefined(this.totalPages) || this.totalPages<=1) {
        return false
      } else {
        return true
      }
    },
    hasDocument() {
      if (isUndefined(this.apiData[this.modelName]) || this.apiData[this.modelName].length==0) {
        return false
      } else {
        return true
      }
    },
    metaData() {
      let meta = {}
      if (!isUndefined(this.hn)) meta['hn'] = this.hn
      if (!isUndefined(this.defaultCategory)) meta['category'] = this.defaultCategory
      if (!isUndefined(this.folder)) meta['folder'] = this.folder
      if (!isUndefined(this.encounterId)) meta['encounterId'] = this.encounterId
      if (!isUndefined(this.referenceId)) meta['referenceId'] = this.referenceId

      return meta
    }
  },
  watch: {
    currentPage: function() {
      this.loadData()
    }
  },
  mounted() {
    this.apiCustomUrl.search = ['models','Document','Documents','search']
    this.apiCustomUrl.create = ['Document','DocumentController','addScannedDocuments']
    this.apiCustomUrl.delete = ['Document','DocumentController','deleteDocument']
    this.apiCustomUrl.update = null
    this.loadData()
  }
}
</script>

<style lang="css" scoped>
</style>
