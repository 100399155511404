import Vue from 'vue'
import EncounterSearch from '@/components/dialog/vEncounterSearch.vue'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.encounter) this.$encounter = options.encounter;
    else if (options.parent && options.parent.$encounter) this.$encounter = options.parent.$encounter
  }
});

export default function (initialFilter = undefined,options) {
  return new Promise(resolve => {
    const component = new Vue(Object.assign(EncounterSearch, {
      destroyed: () => {
        document.getElementById('app').removeChild(component.$el)
        resolve(component.value)
      }
    }))
    Object.assign(component, options, { initialFilter: initialFilter })
    component.$hn = this.$hn
    component.$store = this.$store
    component.$vuetify = this.$vuetify
    component.$mount()
    document.getElementById('app').appendChild(component.$el)
  })
}


