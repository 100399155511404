<template lang="html">
  <v-model-table model-name="Transactions" :model-title="title"
    :model-api="['models','Patient','PatientsTransactions']"
    :model-api-search="transactionQuery"
    :initial-data="initialData"
    :headers="headers" v-if="encounterId"
    :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
    sort-by="product.productType"
    :insertable="!readOnly"
    hide-insert-button
    ref="transaction"
    force-reload
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="10">
            <v-master-field :rules="[rules.require()]" v-model="data.productCode" 
              :custom-api="['models','Master','Products','query']" custom-api-text="productName" 
              custom-api-value="productCode" label="รายการสินค้า"
              show-code
              key="productCode"
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="data.quantity" type="number" label="จำนวน"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-table-field v-model="data.itemizedProducts" title="รายการส่วนประกอบ" :headers="itemizedHeaders" :insertable="false" show-create-pad auto-item-action>
              <template v-slot:item.productName="props">
                <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
              </template>
              <template v-slot:item.quantity="props">
                <v-edit-dialog
                  :return-value.sync="props.item.quantity"
                > 
                  {{ props.item.quantity }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.quantity"
                      type="number"
                      label="Quantity"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:createPad="{data: productData,rules}">
                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="8" md="10">
                      <v-master-field :rules="[rules.require()]" v-model="productData.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-text-field :rules="[rules.require(),rules.numeric()]" label="Qty" v-model="productData.quantity"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-table-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:toolBarButton="props">
      <v-dialog v-model="dialog" max-width="80vw" max-height="80vh" @keydown.esc="cancel" :fullscreen="!$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" v-if="!readOnly">เพิ่มรายการ</v-btn>
        </template>
        <v-card>
          <v-form>
            <v-toolbar flat v-if="!hideToolbar">
              <v-select
                v-model="dlgCriteria.productType"
                :items="productType"
                item-text="text" item-value="value"
                label="ชนิดรายการ"
                single-line hide-details
                class="px-1"
                clearable
              ></v-select>
              <v-master-field v-model="dlgCriteria.category" label="หมวดหมู่สำหรับค้นหา"
                :groupKey="$store.getters.getGroupKey('productCategory')"
                :filterText="dlgCriteria.productType"
                clearable
                show-code
                single-line hide-details
                class="px-1"
              ></v-master-field>

              <v-spacer></v-spacer>
              <v-text-field v-model="dlgKeyword" label="Keyword" single-line hide-details class="px-1"></v-text-field>
            </v-toolbar>
          </v-form>
          <v-card-text class="pa-0">
            <v-model-table
              v-model="dlgSelectedItems"
              model-name="Products"
              model-key="productCode"
              :model-api="['models','Master','Products']"
              :model-api-search="dlgSearchFilter"
              api-scope="selectable"
              :headers="dlgHeaders"
              :keyword="dlgKeyword"
              hide-toolbar
              flat
              multiple-select
              searchable
            >
            </v-model-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dlgChoose(props.onCreateData)" :disabled="!dlgSelectedItems.length">เลือก</v-btn>
            <v-btn color="grey" @click="dialog=false">ยกเลิก</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.invoiceId="props">
      <v-icon v-if="props.item.invoiceId">mdi-receipt</v-icon>&nbsp;
      <v-icon v-if="props.item.invoiceId && props.item.invoice.amountOutstanding==0">mdi-account-cash</v-icon>
    </template>
    <template v-slot:item.productCode="props">
      {{ props.item.productCode }} {{ props.item.product.productName }}
    </template>
    <template v-slot:item.insurance="props">
      <template v-if="props.item.sold_patients_insurances">
        <v-icon alt="เปลี่ยนให้ผู้ป่วยชำระเอง" @click="forceSelfPay(props.item)" v-if="!readOnly && !props.item.invoiceId" v-permission="'transactions-force-selfpay'">mdi-cash-refund</v-icon>
        <v-label-master group-key="payerType" :item-code="props.item.sold_patients_insurances.payerType"></v-label-master>
        <label v-if="props.item.sold_payer">({{props.item.sold_payer.payerName}})</label>
      </template>
      <template v-else>
        <v-icon alt="เปลี่ยนให้ชำระตามสิทธิ์" @click="unforceSelfPay(props.item)" v-if="!readOnly && !props.item.invoiceId && props.item.isForceSelfPay" v-permission="'transactions-force-selfpay'">mdi-account-child-circle</v-icon>
        เงินสด
      </template>
    </template>
    <template v-slot:item.performDoctorCode="props">
      <v-edit-dialog
        :return-value.sync="props.item.performDoctorCode"
        @save="props.actions.saveData(props.item)"
        v-if="props.item.category=='SV04'"
        large
      > 
        <v-icon v-if="!props.item.performDoctorCode">mdi-account-plus</v-icon>
        <v-icon v-else>mdi-account-edit</v-icon>
        <template v-if="props.item.perform_doctor">{{ props.item.perform_doctor.nameTH }}</template>
        <template v-slot:input>
          <v-master-field v-model="props.item.performDoctorCode" :custom-api="['models','Master','Doctors','query']"
            custom-api-text="nameTH" custom-api-value="doctorCode"
            customApiEagerLoad
            label="ระบุแพทย์" show-code
          ></v-master-field>
        </template>
      </v-edit-dialog>
      <template v-else>-</template>
    </template>
    <template v-slot:item.discount="props">
      {{ (props.item.discount==0 || props.item.discount==null) ? "-" : props.item.discount+"%" }}
    </template>
    <template v-slot:item.price="props">
      {{ props.item.price.toFixed(2) }} <v-icon v-if="props.item.price==0" color="orange darken-3">mdi-alert-circle</v-icon>
    </template>
    <template v-slot:item.totalPrice="props">
      {{ props.item.totalPrice.toFixed(2) }} <v-icon v-if="props.item.totalPrice==0" color="orange darken-3">mdi-alert-circle</v-icon>
    </template>
    <template v-slot:item.finalPrice="props">
      {{ props.item.finalPrice.toFixed(2) }} <v-icon v-if="props.item.finalPrice==0" color="orange darken-3">mdi-alert-circle</v-icon>
      <v-tooltip bottom v-if="props.item.finalExcessPrice">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="warning" v-bind="attrs" v-on="on">
            mdi-cash-plus
          </v-icon>
        </template>
        <span>ส่วนเกิน {{ props.item.finalExcessPrice }} บาท</span>
      </v-tooltip>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="props.actions.expand(props.item)" :disabled="!props.item.itemizedProducts || props.item.itemizedProducts.length==0">mdi-file-tree</v-icon>
      <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)" v-if="!readOnly && !props.item.invoiceId">$vuetify.icons.edit</v-icon>
      <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)" v-if="!readOnly && !props.item.invoiceId">$vuetify.icons.delete</v-icon>
    </template>
    <template v-slot:item.quantity="props" v-if="!readOnly">
      <v-edit-dialog
        :return-value.sync="props.item.quantity"
        @save="props.actions.saveData(props.item)"
      > 
        {{ props.item.quantity }}
        <template v-slot:input  v-if="!props.item.invoiceId">
          <v-text-field
            v-model="props.item.quantity"
            type="number"
            label="Quantity"
            single-line
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:expanded-item="{ headers,item,actions }">
      <td :colspan="headers.length" class="pa-2">
        <v-enhance-table
          :items="item.itemizedProducts"
          :headers="itemizedHeaders"
        >
          <template v-slot:item.productName="props">
            <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
          </template>
        </v-enhance-table>
      </td>
    </template>
    <template v-slot:body.append="{allitems,isMobile}">
      <template v-if="isMobile">
        <tr>
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__wrapper">
              <div class="v-data-table__mobile-row__header">รวมค่าใช้จ่าย</div>
              <div class="v-data-table__mobile-row__cell"><label class="font-weight-bold">{{ sumBy(allitems,'finalPrice').toFixed(2) }}</label></div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="v-data-table__mobile-row">
            <div class="v-data-table__mobile-row__wrapper">
              <div class="v-data-table__mobile-row__header">รวมค่าใช้จ่าย ส่วนที่ผู้ป่วยต้องชำระ</div>
              <label class="font-weight-bold">
                {{ (sumBy(allitems,'finalPrice',(o)=>{return !o.sold_patients_insurances || o.sold_patients_insurances.isChargeToPatient}) + sumBy(allitems,'finalExcessPrice',(o)=>{return o.sold_patients_insurances && !o.sold_patients_insurances.isChargeToPatient})).toFixed(2) }}
              </label>
            </div>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td colspan="9"><label class="font-weight-bold">รวมค่าใช้จ่าย</label></td>
          <td>
            <label class="font-weight-bold">{{ sumBy(allitems,'finalPrice').toFixed(2) }}</label>
          </td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="9"><label class="font-weight-bold">รวมค่าใช้จ่าย ส่วนที่ผู้ป่วยต้องชำระ</label></td>
          <td>
            <label class="font-weight-bold">
              {{ (sumBy(allitems,'finalPrice',(o)=>{return !o.sold_patients_insurances || o.sold_patients_insurances.isChargeToPatient}) + sumBy(allitems,'finalExcessPrice',(o)=>{return o.sold_patients_insurances && !o.sold_patients_insurances.isChargeToPatient})).toFixed(2) }}
            </label>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </template>
  </v-model-table>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime'
import { sumBy,filter,isUndefined,isEmpty } from 'lodash'
export default {
  data: ()=>({
    dlgProductType: undefined,
    currentClinic: undefined,
    headers: [
      {
        text: 'Payment',
        align: 'left',
        value: 'invoiceId',
        class: 'body-2'
      },
      {
        text: 'Date-Time',
        value: 'transactionDateTime',
        class: 'body-2'
      },
      {
        text: 'Type',
        value: 'product.productType',
        class: 'body-2'
      },
      {
        text: 'Product',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'Quantity',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'Insurance',
        value: 'insurance',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'performDoctorCode',
        class: 'body-2'
      },
      {
        text: 'Unit Price',
        value: 'price',
        class: 'body-2'
      },
      {
        text: 'Discount',
        value: 'discount',
        class: 'body-2'
      },
      {
        text: 'Final Price',
        value: 'finalPrice',
        class: 'body-2'
      },
      {
        text: 'User',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    dialog: false,
    dlgHeaders: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดสินค้า',
        value: 'productType',
        class: 'body-2'
      },
      {
        text: 'ชื่อภาษาไทย',
        value: 'productName',
        class: 'body-2'
      },
      {
        text: 'ชื่อภาษาอังกฤษ',
        value: 'productNameEN',
        class: 'body-2'
      },
      {
        text: 'ราคา',
        value: 'price1',
        class: 'body-2'
      },
      {
        text: 'ปกส.',
        value: 'price2',
        class: 'body-2'
      },
      {
        text: 'สปสช.',
        value: 'price3',
        class: 'body-2'
      },
      {
        text: 'ราชการ',
        value: 'price4',
        class: 'body-2'
      },
      {
        text: 'ต่างชาติ',
        value: 'price5',
        class: 'body-2'
      },
    ],
    itemizedHeaders: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'รายการประกอบ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2',
      },
    ],
    dlgSelectedItems: [],
    dlgCriteria: {},
    dlgKeyword: null,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    encounterId: {
      type: String,
      default: undefined
    },
    prescriptionId: {
      type: [String, Number],
      default: undefined
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'รายการค่าใช้จ่าย'
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    productsFilter: {
      type: Object,
      default: undefined
    },
    transactionsFilter: {
      type: Object,
      default: undefined
    },
    performDoctorCode: {
      type: String,
      default: undefined
    },
    performClinicCode: {
      type: String,
      default: undefined
    },
    performLocationCode: {
      type: String,
      default: undefined
    },
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
        encounterId: this.encounterId,
        prescriptionId: this.prescriptionId,
        performDoctorCode: this.performDoctorCode,
        performClinicCode: this.performClinicCode,
        performLocationCode: this.performLocationCode,
      }
    },
    transactionQuery() {
      return Object.assign({},this.transactionsFilter,{
        hn: this.hn,
        encounterId: this.encounterId,
        prescriptionId: this.prescriptionId,
      })
    },
    dlgSearchFilter() {
      let tmpFilter = {}

      if (!isUndefined(this.dlgCriteria.productType)) Object.assign(tmpFilter,{productType: this.dlgCriteria.productType})
      if (!isUndefined(this.dlgCriteria.category)) Object.assign(tmpFilter,{category: this.dlgCriteria.category})

      if (!isUndefined(this.productsFilter)) Object.assign(tmpFilter,this.productsFilter)

      return (!isEmpty(tmpFilter)) ? tmpFilter : undefined
    },
    productType() {
      return [
        {text: 'ยา', value: 'medicine'},
        {text: 'เวชภัณฑ์', value: 'supply'},
        {text: 'หัตถการ', value: 'procedure'},
        {text: 'บริการ', value: 'service'},
        {text: 'การตรวจห้องปฏิบัติการ', value: 'laboratory'},
        {text: 'การตรวจทางรังสีวิทยา', value: 'radiology'},
        {text: 'รายการชุด', value: 'package'},
      ]
    }
  },
  methods: {
    sumBy(items,iteratee,condition=undefined) {
      if (!isUndefined(condition)) items = filter(items,condition)
      return sumBy(items,iteratee)
    },
    dlgChoose(create) {
      let createData = this.dlgSelectedItems.map((item)=>{
        let tmpItem = {
          productCode: item.productCode
        }
        return Object.assign({},this.initialData,tmpItem)
      })

      create(createData,{done: ()=>{
        this.dlgSelectedItems = []
        this.$nextTick(()=>{
          this.dialog = false
        })
      }});
    },
    forceSelfPay(item) {
      this.$confirm('ต้องการที่จะเปลี่ยนให้ผู้ป่วยชำระเอง').then((res)=>{
        if (res) {
          item.isForceSelfPay = true
          this.$refs.transaction.saveData(item)
        }
      })
    },
    unforceSelfPay(item) {
      this.$confirm('ต้องการที่จะเปลี่ยนให้ชำระตามสิทธิ์').then((res)=>{
        if (res) {
          item.isForceSelfPay = false
          this.$refs.transaction.saveData(item)
        }
      })
    },
    reload() {
      this.$refs.transaction.loadData()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
