<template lang="html">
  <v-card>
    <v-btn color="primary" fab small top right absolute @click="onDelete"><v-icon>delete</v-icon></v-btn>
    <v-card-text class="pb-0">
      <div align="center">
        <v-img :src="imgSrc" max-height="150" v-if="imgSrc" contain></v-img>
        <v-icon :size="150" v-if="!imgSrc && !isLoading">person</v-icon>
        <v-progress-circular :size="50" color="primary" indeterminate v-if="isLoading"></v-progress-circular>
      </div>
    </v-card-text>
    <v-card-text class="text-xs-center">
      <v-label-datetime :date-time="photo.created_at" short-date-time></v-label-datetime>
    </v-card-text>
  </v-card>
</template>

<script>
import { isPlainObject,isUndefined } from 'lodash'
import helperAsset from '@/modules/helperAsset'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    imgSrc: undefined,
    isLoading: false,
  }),
  props: {
    photo: {
      type: [Object, Array],
      default: ()=>({})
    },
  },
  computed: {

  },
  methods: {
    onDelete() {
      this.$emit('delete')
    },
    loadImgSrc() {
      if (isUndefined(this.photo['base64data'])) {
        if (!isUndefined(this.photo['id'])) {
          // this.isLoading = true
          // helperAsset.getBase64data(this.photo).then((returnData)=>{
          //   this.imgSrc = returnData['base64data']
          // }).finally(()=>{
          //   this.isLoading = false
          // })
          this.imgSrc = api.urlBuilder(['assets',this.photo['id']])
        }
      } else {
        this.imgSrc = this.value['base64data']
      }
    }
  },
  watch: {
    photo: {
      handler(newVal) {
        this.loadImgSrc()
      },
      deep: true
    },
  },
  mounted() {
    this.loadImgSrc()
  },
}
</script>

<style lang="css" scoped>
</style>
