<template lang="html">
  <v-form-pad ref="productSupply" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="6">
            <v-text-field v-model="data.upcCode" label="UPC Code"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
