<template lang="html">
  <v-model-table model-name="Clinics" model-title="Admissions"
    :model-api="['models','Master','Clinics']" model-key="clinicCode"
    :headers="headers"
    :model-api-search="{encounterType: 'IMP'}"
    :initial-data="{encounterType: 'IMP'}"
    importable
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row>
          <v-col cols="12" md="2">
            <v-text-field v-model="data.clinicCode" :rules="[rules.require('Admission Code')]" label="รหัสเตียง" :disabled="!isCreating"></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="data.clinicName" :rules="[rules.require('Admission Name')]" label="ชื่อเตียง"></v-text-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-text-field v-model="data.clinicNameEN" :rules="[rules.require('Admission Name (English)')]" label="ชื่อเตียง (ภาษาอังกฤษ)"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-master-field v-model="data.locationCode" :custom-api="['models','Master','Locations','query']"
              custom-api-text="locationName" custom-api-value="locationCode"
              :filter-text="{locationType: 'ward'}"
              label="สถานที่" show-code></v-master-field>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-tabs-group>
                  <template v-slot:tabs>
                    <v-tab key="document">เอกสารเริ่มต้น</v-tab>
                    <v-tab key="autoCharge">รายการเรียกเก็บอัตโนมัติ</v-tab>
                  </template>
                  <template v-slot:items>
                    <v-tab-item key="document">
                      <v-table-field v-model="data.defaultDocument" title="เอกสารเริ่มต้น" :headers="docHeaders" hide-toolbar show-create-pad auto-item-action>
                        <template v-slot:createPad="{data: tmplData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="12">
                                <v-master-field :rules="[rules.require()]" v-model="tmplData.templateCode" :custom-api="['models','Document','DocumentsTemplates','query']" custom-api-text="templateName" custom-api-value="templateCode" label="เอกสาร" show-code customApiEagerLoad></v-master-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                        <template v-slot:item.templateName="props">
                          <v-label-api :api-url="['models','Document','DocumentsTemplates','find']" :api-key="props.item.templateCode" api-field="templateName" ></v-label-api>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                    <v-tab-item key="autoCharge">
                      <v-table-field v-model="data.autoCharge" title="รายการเรียกเก็บอัตโนมัติ" :headers="chargeHeaders" hide-toolbar show-create-pad auto-item-action>
                        <template v-slot:item.productName="props">
                          <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
                        </template>
                        <template v-slot:createPad="{data: productData,rules}">
                          <v-container fluid pa-0>
                            <v-row>
                              <v-col cols="8" md="10">
                                <v-master-field :rules="[rules.require()]" v-model="productData.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
                              </v-col>
                              <v-col cols="4" md="2">
                                <v-text-field :rules="[rules.require(),rules.numeric()]" label="Qty" v-model="productData.quantity"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="3">
                                <v-text-field :rules="[rules.numeric()]" label="Repeat (Hours)" v-model="productData.repeatHour"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="3">
                                <v-text-field :rules="[rules.numeric()]" label="Round (Hours)" v-model="productData.roundHour"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="3">
                                <v-text-field :rules="[rules.numeric()]" label="Limit per Encounter" v-model="productData.limitPerEncounter"></v-text-field>
                              </v-col>
                              <v-col cols="6" md="3">
                                <v-text-field :rules="[rules.numeric()]" label="Limit per Day" v-model="productData.limitPerDay"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </v-table-field>
                    </v-tab-item>
                  </template>
                </v-tabs-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.locationCode="props">
      <v-label-api :api-url="['models','Master','Locations','find']" :api-key="props.item.locationCode" api-field="locationName" ></v-label-api>
    </template>
  </v-model-table>
</template>
<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสเตียง',
        value: 'clinicCode',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ชื่อเตียง',
        value: 'clinicName',
        class: 'body-2'
      },
      {
        text: 'ชื่อเตียง (English)',
        value: 'clinicNameEN',
        class: 'body-2'
      },
      {
        text: 'สถานที่',
        value: 'locationCode',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      },
    ],
    docHeaders: [
      {
        text: 'รหัสเอกสาร',
        value: 'templateCode',
        class: 'body-2',
      },
      {
        text: 'ชื่อเอกสาร',
        value: 'templateName',
        class: 'body-2',
      },
    ],
    chargeHeaders: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
      },
      {
        text: 'รายการเรียกเก็บ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'Repeat (hrs)',
        value: 'repeatHour',
        class: 'body-2',
      },
      {
        text: 'Round (hrs)',
        value: 'roundHour',
        class: 'body-2',
      },
      {
        text: 'Limit-Enc',
        value: 'limitPerEncounter',
        class: 'body-2',
      },
      {
        text: 'Limit-Day',
        value: 'limitPerDay',
        class: 'body-2',
      },
    ]
  }),
}
</script>

<style lang="css">
</style>
