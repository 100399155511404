<template lang="html">
  <v-info-dialog form-title="ข้อความของผู้ป่วย (Patient's note)" ref="messagesDialog" hide-title>
    <template v-slot:activateButton="{on,disabled}">
      <v-badge
        color="red lighten-1"
        :content="messagesCount"
        overlap bordered
        :value="messagesCount"
      >
        <v-btn v-on="on" :disabled="disabled" :color="iconColor" icon large><v-icon>mdi-message-text</v-icon></v-btn>
      </v-badge>
    </template>
    <template v-slot:content>
      <v-model-table model-name="PatientsMessages" model-title="ข้อความของผู้ป่วย (Patient's note)"
        :model-api="['models','Patient','PatientsMessages']"
        :model-api-search="messagesQuery"
        api-scope="activeLocation"
        :api-scope-param="currentLocation"
        :initial-data="initialData"
        :headers="headers"
        flat
      >
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-container>
            <v-row fluid>
              <v-col cols="12" md="2">
                <v-master-field :rules="[rules.require()]" v-model="data.importantLevel" label="ความสำคัญ" groupKey="$MessageImportantLevel"></v-master-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-date-time-field v-model="data.endDateTime" label="วันที่สิ้นสุด"></v-date-time-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.locations"
                  :custom-api="['models','Master','Locations','query']" custom-api-text="locationName" 
                  custom-api-value="locationCode" label="ระบุสถานที่รับ" 
                  show-code
                  customApiEagerLoad
                  multiple
                ></v-master-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-textarea :rules="[rules.require()]" v-model="data.message" label="ข้อความ" auto-grow></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.importantLevel="props">
          <v-label-master groupKey="$MessageImportantLevel" :itemCode="props.item.importantLevel"></v-label-master>
        </template>
        <template v-slot:item.message="props">
          <span style="white-space: pre;">{{ props.item.message}}</span>
        </template>
        <template v-slot:item.action="props">
          <v-icon v-if="$store.getters.getCurrentUser" class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
          <v-icon v-if="$store.getters.getCurrentUser && props.item.created_by==$store.getters.getCurrentUser.username" class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
        </template>
      </v-model-table>
    </template>
  </v-info-dialog>
</template>

<script>
import helperItem from '@/modules/helperItem'
import { isUndefined,find,maxBy } from 'lodash'

export default {
  data: ()=>({
    currentTimerId: undefined,
    currentLocation: undefined,
    messagesData: undefined,
    lastMessageId: undefined,
    headers: [
      {
        text: "วันที่-เวลา",
        value: "beginDateTime",
        class: "body-2",
        width: "200px",
      },
      {
        text: "ความสำคัญ",
        value: "importantLevel",
        class: "body-2",
        width: '150px'
      },
      {
        text: "ข้อความ",
        value: "message",
        class: "body-2",
      },
      {
        text: "วันที่สิ้นสุด",
        value: "endDateTime",
        class: "body-2",
        width: "200px",
      },
      {
        text: "Action",
        value: "action",
        class: "body-2",
        width: "100px",
      },
    ]
  }),
  computed: {
    iconColor() {
      return (this.messagesData && this.messagesData.length>0) ? 'warning' : undefined
    },
    messagesCount() {
      return (this.messagesData) ? this.messagesData.length : 0
    },
    initialData() {
      return {
        hn: this.hn,
        importantLevel: 'ordinary'
      }
    },
    messagesQuery() {
      return {
        hn: this.hn,
      }
    }
  },
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  methods: {
    loadMessages() {
      helperItem.searchItem(['models','Patient','PatientsMessages'],{hn: this.hn},undefined,undefined,'activeLocation',this.currentLocation).then((returnData)=>{
        this.messagesData = returnData
        if (isUndefined(this.lastMessageId) || this.lastMessageId < maxBy(this.messagesData,'id')) {
          let severe = find(returnData,{importantLevel: 'critical'})
          if (severe) {
            this.$refs.messagesDialog.showDialog()
          }
          this.lastMessageId = maxBy(this.messagesData,'id')
        }
      }).catch((e)=>{
        void e
        this.messagesData = undefined
      })
    },
  },
  watch: {
    hn: function () {
      this.loadMessages()
    }
  },
  mounted() {
    let location = this.$store.getters.getConfig('location')
    if (location && location.locationCode) this.currentLocation = location.locationCode
    this.loadMessages()
    this.currentTimerId = setInterval(() => {
      this.loadMessages()
    }, 1000 * 30)
  },
  beforeDestroy() {
    clearInterval(this.currentTimerId)
  }
}
</script>

<style lang="css" scoped>
</style>
