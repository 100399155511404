export let moduleMenu = [
  {
    title: 'Pharmacy',
    action: 'mdi-pill',
    menuItems: [
      {
        title: 'Pharmacy (Home med)',
        action: 'mdi-pill',
        link: { name: 'pharmacy.pharmacyroom' }
      },
      {
        title: 'Pharmacy (In hospital)',
        action: 'mdi-needle',
        link: { name: 'pharmacy.pharmacyinhospital' }
      },
      {
        title: 'Allergies',
        action: 'mdi-skull',
        link: { name: 'pharmacy.allergies' }
      },
    ],
    permission: 'pharmacy',
  },
]

export let moduleRoute = [
  {
    path: '/pharmacy/PharmacyRoom/:prescriptionId?',
    name: 'pharmacy.pharmacyroom',
    component: () => import('./PharmacyRoom'),
    props: true,
  },
  {
    path: '/pharmacy/PharmacyInHospital/:prescriptionId?',
    name: 'pharmacy.pharmacyinhospital',
    component: () => import('./PharmacyInHospital'),
    props: true,
  },
  {
    path: '/pharmacy/Allergies',
    name: 'pharmacy.allergies',
    component: () => import('./Allergies'),
    props: true,
  },
]