<template lang="html">
  <v-model-table model-name="Codes" model-title="รหัสการรับรองสิทธิ์"
    api-order-by="issuedDateTime,desc"
    :model-api="['models','Patient','PatientsCodes']"
    :model-api-search="codeQuery" :initial-data="initialData"
    :headers="headers" v-if="hn"
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="12" md="4">
            <v-date-field v-model="data.issuedDateTime" label="วันที่ออกรหัส" :rules="[rules.require()]"></v-date-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field v-model="data.code" label="รหัส" :rules="[rules.require()]"></v-text-field>
          </v-col>
          <v-col cols="12" v-if="!isCreating && data.id">
            <v-document-scan :hn="data.hn" :referenceId="'code_'+data.id" folder="insurance" :available-category="['800']" default-category="800"></v-document-scan>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.issuedDateTime="props">
      <v-label-datetime short-date :date-time="props.item.issuedDateTime"></v-label-datetime>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import helperDateTime from 'tantee-common/lib/helperDateTime';

export default {
  data: ()=>({
    headers: [
      {
        text: 'วันที่ออกรหัส',
        value: 'issuedDateTime',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ชนิดรหัส',
        value: 'codeType',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'รหัส',
        value: 'code',
        class: 'body-2',
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    codeType: {
      type: String,
      default: 'nhsoauthcode'
    }
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
        codeType: this.codeType,
        issuedDateTime: helperDateTime.today().format("YYYY-MM-DD")
      }
    },
    codeQuery() {
      return {
        hn: this.hn,
        codeType: this.codeType,
      }
    }
  },
}
</script>

<style lang="css">
</style>
