<template lang="html">
  <v-model-table model-name="Names" model-title="Names"
    :model-api="['models','Patient','PatientsNames']"
    :model-api-search="{'hn': hn}" :initial-data="{'hn': hn, 'nameType': 'TH'}"
    :headers="headers" v-if="hn" v-bind="$attrs" v-on="$listeners">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="data.nameType" row>
              <v-radio v-for="type in nameType" :label="type.text" :value="type.value" :key="type.value"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-patient-name v-model="data" :english="(data.nameType=='TH' || data.nameType=='ALIAS_TH') ?  false : true"></v-patient-name>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.nameType="props">
      {{ nameTypeText(props.item.nameType) }}
    </template>
    <template v-slot:item.firstName="props">
      <v-label-name :name-data="props.item"></v-label-name>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { find } from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'ชนิดชื่อ',
        value: 'nameType',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'firstName',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    nameType: [
      {text:'ชื่อไทย', value: 'TH'},
      {text:'ชื่ออังกฤษ', value: 'EN'},
      {text:'นามแฝงไทย', value: 'ALIAS_TH'},
      {text:'นามแฝงอังกฤษ', value: 'ALIAS_EN'},
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  methods: {
    nameTypeText(type) {
      return (find(this.nameType,['value',type])) ? find(this.nameType,['value',type])['text'] : ""
    }
  }
}
</script>

<style lang="css">
</style>
