import api from 'tantee-common/lib/api'

export default async function(scanParam=undefined) {
  let apiUrl = ['localDevice','Scanner','scan']
  let returnData = []

  await api.get(apiUrl,scanParam).then((scannerData) => {
    if (scannerData.success) {
      returnData = scannerData.data
    } else {
      if (scannerData.errorTexts.length>0) {
        scannerData.errorTexts.forEach((error) => {
          this.$store.dispatch('addAlertItem',{
            alertLocation: 'top',
            alertType: 'error',
            alertText: error
          })
        })
      }
    }
  }).catch((e) => void e)

  return returnData
}
