<template lang="html">
  <v-editable-pad v-model="patientData" title="ข้อมูลทั่วไป" v-slot="{data,rules,disabled}" :update-api="['models','Patient','Patients','update']">
    <v-card flat>
      <v-card-text class="py-1">
        <v-container pa-0 fluid>
          <v-row dense>
            <v-col cols="6" md="2">
              <v-date-field v-model="data.dateOfBirth" :readonly="disabled" prepend-icon="event" label="วัน-เดือน-ปี เกิด"
                :rules="[rules.require()]"
                birth-date
              ></v-date-field>
            </v-col>
            <v-col cols="6" md="2">
              <v-radio-group v-model="data.sex" row label="เพศ" :rules="[rules.require()]" :readonly="disabled">
                <v-spacer></v-spacer>
                <v-radio class="mr-1" label="ชาย" :value="1"></v-radio>
                <v-radio label="หญิง" :value="2"></v-radio>
                <v-spacer></v-spacer>
              </v-radio-group>
            </v-col>
            <v-col cols="6" md="2">
              <v-master-field v-model="data.maritalStatus" label="สถานะภาพ"
                :groupKey="$store.getters.getGroupKey('maritalStatus')"
                clearable
                :readonly="disabled"
              ></v-master-field>
            </v-col>
            <v-col cols="6" md="3">
              <v-master-field v-model="data.religion" label="ศาสนา"
                :groupKey="$store.getters.getGroupKey('religion')"
                clearable
                :readonly="disabled"
              ></v-master-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-master-field v-model="data.occupation" label="อาชีพ"
                :groupKey="$store.getters.getGroupKey('occupation')"
                clearable
                :readonly="disabled"
              ></v-master-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.paternalName" label="ชื่อบิดา" :readonly="disabled"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.maternalName" label="ชื่อมารดา" :readonly="disabled"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.spouseName" label="ชื่อคู่สมรส" :readonly="disabled"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.primaryMobileNo" label="เบอร์โทรศัพท์มือถือ" :rules="[rules.require()]" :readonly="disabled"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.primaryTelephoneNo" label="เบอร์โทรศัพท์บ้าน" :readonly="disabled"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="data.primaryEmail" label="Email" :readonly="disabled"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-editable-pad>
</template>

<script>
import { isEqual } from 'lodash'

export default {
  data: ()=>({
    patientData : {}
  }),
  props: {
    value: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.patientData = Object.assign({},newVal)
      },
      deep: true
    },
    patientData: {
      handler(newVal) {
        if (!isEqual(newVal,this.value)) this.$emit('input',newVal)
      },
      deep: true
    }
  },
  mounted() {
    this.patientData = Object.assign({},this.value)
  }
}
</script>

<style lang="css" scoped>
</style>
