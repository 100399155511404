export let moduleMenu = [
  {
    title: 'Reports',
    action: 'mdi-file-chart',
    menuItems: [
      {
        title: 'Doctor Fee',
        link: { name: 'report.doctorfee' },
        permission: 'report-doctorfee'
      },
      {
        title: 'HD Unit Invoice',
        link: { name: 'report.hdunitinvoice' },
        permission: 'report-hdunitinvoice'
      },
    ],
    permission: 'report'
  }
]

export let moduleRoute = [
  {
    path: '/reports/DoctorFee',
    name: 'report.doctorfee',
    component: () => import('./DoctorFee')
  },
  {
    path: '/reports/HDUnitInvoice',
    name: 'report.hdunitinvoice',
    component: () => import('./HDUnitInvoice')
  },
]