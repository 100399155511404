import api from 'tantee-common/lib/api'
import {isUndefined} from 'lodash'

export default {
  methods: {
    printDocument(documentId,templateCode=undefined) {
      let printData = {
        documentId: documentId
      }
      if (!isUndefined(templateCode)) printData['templateCode'] = templateCode

      let printPromise = api.post(['Document','PrintController','printDocument'],printData).then((returnData)=>{
        this.$printPdfBase64(returnData['data'][0])
      }).catch((e)=>void e)
      this.$loading('Printing',printPromise)
    },

    printTemporary(hn,encounterId,templateCode,data) {
      let printData = {
        hn: hn,
        encounterId: encounterId,
        templateCode: templateCode,
        data: data,
      }
      if (!isUndefined(templateCode)) printData['templateCode'] = templateCode

      let printPromise = api.post(['Document', 'PrintController','genericPrintDocument'],printData).then((returnData)=>{
        this.$printPdfBase64(returnData['data'][0])
      }).catch((e)=>void e)
      this.$loading('Printing',printPromise)
    }
  }
}