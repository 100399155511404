import Vue from 'vue'
import PatientSearch from '@/components/dialog/vPatientSearch.vue'

Vue.mixin({
  beforeCreate() {
    const options = this.$options
    if (options.hn) this.$hn = options.hn;
    else if (options.parent && options.parent.$hn) this.$hn = options.parent.$hn
  }
});

export default function (options) {
  return new Promise(resolve => {
    const component = new Vue(Object.assign(PatientSearch, {
      destroyed: () => {
        document.getElementById('app').removeChild(component.$el)
        resolve(component.value)
      }
    }))
    Object.assign(component, options)
    component.$vuetify = this.$vuetify
    component.$mount()
    document.getElementById('app').appendChild(component.$el)
  })
}


