<template lang="html">
  <v-model-table model-name="dispensings" model-title="รายการบริหารยา"
    :model-api="['models','Registration','EncountersDispensings']"
    :model-api-search="encountersDispensingsQuery"
    :api-with="['product','transaction']"
    :headers="dispensingsHeaders"
    :initial-data="dispensingsInitialData"
    :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
    ref="dispensingsTable"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="10">
            <v-master-field :rules="[rules.require()]" v-model="data.productCode" 
              :custom-api="['models','Master','Products','query']"
              custom-api-scope="availableAt"
              :custom-api-scope-param="[['stockId','=',99999],['encounterId','=',encounterId]]"
              custom-api-text="productName" 
              custom-api-value="productCode" 
              label="รายการ" show-code
              customApiEagerLoad
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-text-field :rules="[rules.require(),rules.numeric()]" label="จำนวน" v-model="data.quantity"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Lot No" v-model="data.lotNo"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:toolBarItem="props">
      <v-btn color="primary" @click="chargeAll" class="ml-1">คิดค่าใช้จ่าย</v-btn>
      <v-btn color="primary" @click="dispenseAll" class="ml-1">จ่ายยาทั้งหมด</v-btn>
      <v-btn color="error" @click="unchargeAll" class="ml-1">ยกเลิกค่าใช้จ่าย</v-btn>
      <v-spacer></v-spacer>
    </template>
    <template v-slot:item.isNotCharge="props">
      {{ props.item.isNotCharge ? 'ไม่คิด' : 'คิด'}}
    </template>
    <template v-slot:item.action="props">
      <v-btn @click.stop="dispense(props.item,props.actions)" class="ml-1" color="primary" v-if="props.item.status!='dispensed'">จ่ายยา</v-btn>
      <v-btn @click.stop="setNotCharge(props.item,props.actions)" class="ml-1" color="primary" v-if="!props.item.transactionId && !props.item.isNotCharge">ไม่คิดเงิน</v-btn>
      <v-btn @click.stop="setCharge(props.item,props.actions)" class="ml-1" color="primary" v-if="!props.item.transactionId && props.item.isNotCharge">คิดเงิน</v-btn>
      <v-btn @click.stop="props.actions.deleteData(props.item)" color="error" class="ml-1" v-if="!props.item.transactionId || (props.item.transaction && !props.item.transaction.invoiceId)"><v-icon class="mx-1">$vuetify.icons.delete</v-icon> ลบ</v-btn>
    </template>
  </v-model-table>
</template>

<script>
import api from 'tantee-common/lib/api';

export default {
  data: () => ({
    dispensingsHeaders: [
      {
        text: 'วัน-เวลา',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: "รหัสสินค้า",
        value: "productCode",
        class: "body-2",
        width: "150px",
      },
      {
        text: "รายการ",
        value: "product.productName",
        class: "body-2",
      },
      {
        text: "จำนวน",
        value: "quantity",
        class: "body-2",
      },
      {
        text: "คิดเงิน",
        value: "isNotCharge",
        class: "body-2",
        width: "100px",
      },

      {
        text: "สถานะ",
        value: "status",
        class: "body-2",
      },
      {
        text: 'User',
        value: 'created_by',
        class: 'body-2'
      },
      {
        text: "Action",
        value: "action",
        align: "center",
        width: "350px",
        sortable: false,
        class: "body-2",
      },
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined,
    },
    encounterId: {
      type: String,
      default: undefined,
    },
    transactionsTable: {
      type: Object,
      default: undefined,
    }
  },
  computed: {
    encountersDispensingsQuery() {
      return {
        encounterId: this.encounterId,
        stockId: 99999,
      };
    },
    dispensingsInitialData() {
      return {
        encounterId: this.encounterId,
        stockId: 99999,
      };
    },
  },
  methods: {
    chargeAll() {
      let postData = {
        encounterId: this.encounterId,
        stockId: 99999,
      };

      let chargePromise = api.post(["Encounter", "DispensingController", "chargeDispensingAll"],postData).then(() => {
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData();
        if (this.transactionsTable) this.transactionsTable.reload();
      }).catch((e) => {
        void e;
      });

      this.$loading("Charging", chargePromise);
    },
    unchargeAll() {
      let postData = {
        encounterId: this.encounterId,
        stockId: 99999,
      };

      let chargePromise = api.post(["Encounter", "DispensingController", "unchargeDispensingAll"],postData).then(() => {
        if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData();
        if (this.transactionsTable) this.transactionsTable.reload();
      }).catch((e) => {
        void e;
      });

      this.$loading("Charging", chargePromise);
    },
    dispenseAll() {
      let postData = {
        encounterId: this.encounterId,
      };

      let dispensePromise = api.post(["Encounter", "DispensingController", "dispenseEncounter"],postData).then(() => {
         if (this.$refs.dispensingsTable) this.$refs.dispensingsTable.loadData();
      }).catch((e) => {
        void e;
      });

      this.$loading("Dispensing", dispensePromise);
    },
    dispense(item, action) {
      item.status = "dispensed";
      action.saveData(item);
    },
    setCharge(item, action) {
      item.isNotCharge = false;
      action.saveData(item);
    },
    setNotCharge(item, action) {
      item.isNotCharge = true;
      action.saveData(item);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
