export let moduleMenu = [
  {
    title: 'Cashier',
    action: 'mdi-cash-multiple',
    menuItems: [
      {
        title: 'Cashier Counter',
        action: 'mdi-cash-multiple',
        link: { name: 'cashier.cashiercounter' },
        permission: 'cashier-counter',
      },
      {
        title: 'Payment History',
        action: 'mdi-history',
        link: { name: 'cashier.paymenthistory' },
        permission: 'cashier-counter',
      },
    ],
    permission: 'cashier',
  },
]

export let moduleRoute = [
  {
    path: '/cashier/CashierCounter',
    name: 'cashier.cashiercounter',
    component: () => import('./CashierCounter')
  },
  {
    path: '/cashier/PaymentHistory',
    name: 'cashier.paymenthistory',
    component: () => import('./PaymentHistory')
  },
]