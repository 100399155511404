<template lang="html">
  <v-form-pad ref="productService" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="12" text-xs-center>
            No specification            
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
