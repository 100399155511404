<template lang="html">
  <v-model-table model-name="radiologies" model-title="รายการการตรวจทางรังสีวินิจฉัย"
    :model-api="['models','Radiology','Radiologies']"
    :model-api-search="searchQuery"
    api-with="reportDocument"
    api-order-by="studyDateTime,desc"
    :headers="headers"
    :insertable="false"
  >
    <template v-slot:item.studyDateTime="props">
      <v-label-datetime :dateTime="props.item.studyDateTime" tiny-date-time></v-label-datetime>
    </template>
    <template v-slot:item.reportingDoctorCode="props">
      {{ (props.item.reporting_doctor && props.item.reporting_doctor.nameTH) ? props.item.reporting_doctor.nameTH : 'ไม่ระบุ' }}
    </template>
    <template v-slot:item.reportDocumentId="props">
      <template v-if="props.item.report_document && props.item.report_document.status=='approved'">
        <v-btn color="primary" @click="viewDocument(props.item.report_document)"><v-icon>mdi-file-document-outline</v-icon>ผลอ่าน</v-btn>
      </template>
    </template>
    <template v-slot:item.action="props">
      <v-btn color="primary" @click="openPacs(props.item.uid)">เปิด</v-btn>
    </template>
  </v-model-table>
</template>

<script>
import api from 'tantee-common/lib/api'
import pacs from '@/components/mixins/pacs'

export default {
  mixins: [pacs],
  data: ()=>({
    headers: [
      {
        text: 'วันที่ทำการตรวจ',
        value: 'studyDateTime',
        class: 'body-2'
      },
      {
        text: 'Accession No',
        value: 'accessionNumber',
        class: 'body-2'
      },
      {
        text: 'ชนิด',
        value: 'modality',
        class: 'body-2'
      },
      {
        text: 'ส่วนที่ตรวจ',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'แพทย์ผู้ส่งตรวจ',
        value: 'referringDoctor',
        class: 'body-2'
      },
      {
        text: 'รังสีแพทย์',
        value: 'reportingDoctorCode',
        class: 'body-2'
      },
      {
        text: 'จำนวนภาพ',
        value: 'imageCount',
        class: 'body-2'
      },
      {
        text: 'ผลการตรวจ',
        value: 'reportDocumentId',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ]
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    modality: {
      type: String,
      default: undefined
    }
  },
  computed: {
    searchQuery() {
      return {
        hn: this.hn,
        modality: this.modality,
      }
    },
  },
  methods: {
    viewDocument(document) {
      this.$documentViewer(document,{fullscreen: false})
    }
  },
}
</script>

<style lang="css" scoped>
</style>
