<template lang="html">
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Admission Registration</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-model-table model-name="Encounters" model-title="Current admission"
        :model-api="['models','Registration','Encounters']" model-key="encounterId"
        :model-api-search="encounterQuery" 
        :initial-data="{'hn': hn, 'encounterType': 'IMP'}"
        :headers="headers" v-if="hn"
        :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
        :limit-total-items="1"
        >
        <template v-slot:modelForm="{data,rules,isCreating}">
          <v-container pa-0 fluid>
            <v-row>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.clinicCode" :custom-api="['models','Master','Clinics','query']"
                  custom-api-text="clinicName" custom-api-value="clinicCode"
                  :filter-text="{encounterType: 'IMP'}"
                  customApiEagerLoad
                  :rules="[rules.require()]"
                  label="คลินิก" show-code></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.locationCode" :custom-api="['models','Master','Locations','query']"
                  custom-api-text="locationName" custom-api-value="locationCode"
                  :filter-text="{locationType: 'ward'}"
                  :value-object.sync="currentLocation"
                  customApiEagerLoad
                  :rules="[rules.require()]"
                  label="Ward" show-code></v-master-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select :label="(currentLocation) ? currentLocation.locationName : 'เตียง'"
                  :items="(currentLocation) ? currentLocation.subunits : undefined"
                  v-model="data.locationSubunitCode"
                  item-text="subunitName"
                  item-value="subunitCode"
                  :rules="[rules.require()]"
                >
                </v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-master-field v-model="data.doctorCode" :custom-api="['models','Master','Doctors','query']"
                  custom-api-text="nameTH" custom-api-value="doctorCode"
                  :filter-text="(currentClinic && currentClinic.specialty) ? {specialty: currentClinic.specialty} : undefined"
                  customApiEagerLoad
                  :rules="[rules.require()]"
                  label="แพทย์" show-code></v-master-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-date-time-field v-model="data.admitDateTime" :rules="[rules.require()]" label="วัน-เวลา admit" :defaultDate="true" fixedDate></v-date-time-field>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.admitDateTime="props">
          <v-label-datetime :date-time="props.item.admitDateTime" short-date-time></v-label-datetime>
        </template>
        <template v-slot:item.locationSubunitCode="props">
          <v-label-items :items="props.item.location.subunits" item-key="subunitCode" item-value="subunitName" :value="props.item.locationSubunitCode"></v-label-items>
        </template>
        <template v-slot:item.action="props">
          <v-icon class="mx-1" @click.stop="printWristBand(props.item)">mdi-watch-variant</v-icon>
          <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
          <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)" v-if="!props.item.transactions || props.item.transactions.length==0">$vuetify.icons.delete</v-icon>
        </template>
      </v-model-table>
    </v-card-text>
  </v-card>
</template>

<script>
import printLabel from '@/components/mixins/printLabel'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import helperPatient from '@/modules/helperPatient'
import api from 'tantee-common/lib/api'
import { isUndefined,find } from 'lodash'

export default {
  mixins: [printLabel],
  data: ()=>({
    currentClinic: undefined,
    currentLocation: undefined,
    headers: [
      {
        text: 'เวลา Admit',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'Ward',
        value: 'location.locationName',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ห้อง',
        value: 'locationSubunitCode',
        class: 'body-2'
      },
      {
        text: 'ชนิดเตียง',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  computed: {
    encounterQuery() {
      let result = {
        hn: this.hn,
        encounterType: 'IMP',
        'whereNull#dischargeDateTime': null
      }
      return result
    },
  },
  methods: {
    printWristBand(encounter) {
      this.printLabel('patient_wristband',encounter)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
