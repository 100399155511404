import Store from '@/store'
import api from 'tantee-common/lib/api'
import helperItem from '@/modules/helperItem'
import * as helper from '@/modules/helperClient'
import { isEmpty } from 'lodash'

export function getComputerName() {
  let apiUrl = ['localDevice','Machine','ComputerName']

  return new Promise((resolve)=>{
    let computerName = localStorage.getItem('computerName')

    if (Store.getters.getComputerName) {
      resolve(Store.getters.getComputerName)
      return
    }

    api.get(apiUrl,undefined,{},true).then((returnData)=>{
      if (returnData.success) {
        computerName = returnData.data.NetBIOS
      }
    }).catch((e)=>{
      void e
    }).finally(()=>{
      if (!computerName) {
        computerName = Math.random().toString(36).substr(2, 5)
      }
      localStorage.setItem('computerName',computerName)
      Store.commit('setComputerName',computerName)
      resolve(computerName)
    })
  })
}

export function getIpAddress() {
  let apiUrl = ['localDevice','Machine','IpAddress']

  return new Promise((resolve,reject)=>{
    api.get(apiUrl).then((returnData)=>{
      if (returnData.success) {
        resolve(returnData.data)
      } else {
        reject('No IP Address available')
      }
    }).catch((e)=>{
      reject('Can not get IP Address. Check RestApiDevice availability.')
    })
  })
}

export function clientStamp() {
  let apiUrl = ['Client','ClientController','clientStamp']

  return new Promise((resolve)=>{
    helper.getComputerName().then((computerName)=>{
      api.post(apiUrl,{clientId: computerName}).then((returnData)=>{
        resolve(returnData.data)
      }).catch((e)=>{
        void e
        resolve()
      })
    })
  })
}

export function getMachineConfig() {
  let apiUrl = ['models','Client','Clients']

  return new Promise((resolve)=>{
    helper.getComputerName().then((computerName)=>{
      helperItem.firstItem(apiUrl,{clientId: computerName}).then((returnData)=>{
        resolve(returnData.configuration)
      }).catch((e)=>{
        void e
        resolve({})
      })
    })
  })
}

export function loadMachineConfig() {
  return new Promise((resolve)=>{
    helper.getMachineConfig().then((configData)=>{
      if (configData && configData.api && configData.api.default) delete configData.api.default
      if (configData && configData.api && configData.api.authentication) delete configData.api.authentication

      if (!isEmpty(configData)) {
        Object.keys(configData).forEach((key)=>{
          Store.commit('addConfig',{
            configName: key,
            configItems: configData[key]
          })
        })
      }
    }).finally(()=>{
      resolve()
    })
  })
}

export function dumpMachineConfig() {
  let api = Store.getters.getConfig('api')
  let location = Store.getters.getConfig('location')
  let locale = Store.getters.getConfig('locale')

  return new Promise((resolve)=>{
    helper.setMachineConfig('api',api).then(()=>{
      helper.setMachineConfig('location',location).then(()=>{
        helper.setMachineConfig('locale',locale).then(()=>{
          resolve()
        })
      })
    })
  })
}

export function setMachineConfig(configName,configValue) {
  let apiUrl = ['Client','ClientController','clientSaveConfig']

  return new Promise((resolve)=>{
    helper.getComputerName().then((computerName)=>{
      api.post(apiUrl,{clientId: computerName,configName: configName, configValue: configValue}).then((returnData)=>{
        resolve(returnData.data)
      }).catch((e)=>{
        void e
        resolve()
      })
    })
  })
}

export default helper