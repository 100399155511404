<template lang="html">
  <v-card>
    <v-card-text>
      <v-container v-if="currentEncounter" pa-0>
        <v-row>
          <v-col cols="6">
            <span class="headline font-weight-bold">วัน-เวลา เข้าโรงพยาบาล</span>&nbsp;
            <span class="headline font-weight-regular"><v-label-datetime :date-time="currentEncounter.admitDateTime" tinyDateTime></v-label-datetime></span>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="primary" @click="printLabel('patient_sticker_blood_tube',currentEncounter)"><v-icon>print</v-icon>Sticker</v-btn>&nbsp;
            <v-encounter-discharge-btn :encounter="currentEncounter" @encounterDischarged="onEncounterUnloaded" @encounterCancelDischarged="onEncounterUnloaded"></v-encounter-discharge-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-patient-vitalsign :encounterId="currentEncounter.encounterId"></v-patient-vitalsign>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-encounter-autocharges :encounterId="currentEncounter.encounterId" flat></v-encounter-autocharges>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import encounterLoader from '@/components/mixins/encounterLoader'
import printLabel from '@/components/mixins/printLabel'

export default {
  mixins: [encounterLoader,printLabel],
}
</script>

<style lang="css" scoped>
</style>
