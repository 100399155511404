<template lang="html">
  <v-model-table model-name="StocksCards" model-title="Stocks Cards"
    :model-api="['models','Stock','StocksCards']"
    :model-api-search="computedFilter" 
    api-order-by="cardDateTime,DESC"
    api-with="product"
    :headers="headers" importable searchable
    server-pagination
    v-bind="$attrs" v-on="$listeners"
    :initialData="initialData"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row fluid>
          <v-col cols="2">
            <v-select
              :rules="[rules.require()]"
              v-model="data.cardType"
              :items="cardType"
              item-text="text" item-value="value"
              label="ชนิดรายการ"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="data.description" label="รายละเอียดรายการ"></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-date-field v-model="data.cardDateTime" label="ระบุวันที่รายการ"></v-date-field>
          </v-col>
          <v-col cols="8">
            <v-master-field :rules="[rules.require()]" v-model="data.productCode" 
              :custom-api="['models','Master','Products','query']" custom-api-scope="stockable"
              custom-api-text="productName" custom-api-value="productCode"
              label="รายการสินค้า" show-code
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-text-field v-model="data.lotNo" label="Lot No." v-if="data.cardType=='receive'"></v-text-field>
            <v-master-field v-model="data.lotNo" 
              :custom-api="['models','Stock','StocksProducts','query']"
              :filterText="(data.productCode) ? {productCode: data.productCode, stockId: stockId} : undefined"
              custom-api-text="lotNo" custom-api-value="lotNo"
              waitForFilter waitForFilterText="กรุณาเลือกรายการสินค้า" customApiEagerLoad
              label="Lot No." clearable
              :value-object.sync="currentStocksProducts"
              v-else
            ></v-master-field>
          </v-col>
          <v-col cols="2">
            <v-hidden-field v-model="data.expiryDate" :item-value="(currentStocksProducts && currentStocksProducts.expiryDate) ? currentStocksProducts.expiryDate : undefined"></v-hidden-field>
            <v-date-field v-model="data.expiryDate" label="Expiry Date" :disabled="!!currentStocksProducts && data.cardType!='receive'"></v-date-field>
          </v-col>
          <v-col cols="4">
            <v-master-field :rules="[rules.requireIf(data.cardType=='dispensing' || (data.cardType=='correction' && data.stockTo==undefined))]" v-model="data.stockFrom" 
              :custom-api="['models','Stock','Stocks','query']"
              custom-api-text="stockName" custom-api-value="id"
              label="จากคลังสินค้า" show-code
              customApiEagerLoad
            ></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-master-field :rules="[rules.requireIf(data.cardType=='transfer' || data.cardType=='receive'|| (data.cardType=='correction' && data.stockFrom==undefined))]" v-model="data.stockTo" 
              :custom-api="['models','Stock','Stocks','query']"
              custom-api-text="stockName" custom-api-value="id"
              label="ไปยังคลังสินค้า" show-code
              customApiEagerLoad
            ></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-text-field :rules="[rules.require()]" v-model="data.quantity" label="จำนวน" type="number"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.cardType="props">
      {{ cardTypeText(props.item.cardType) }}
    </template>
    <template v-slot:item.productCode="props">
      <span class="font-weight-bold">{{ props.item.productCode }}</span> {{ props.item.product.productName }}
    </template>
    <template v-slot:item.stockFrom="props">
      <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockFrom" api-field="stockName" v-if="props.item.stockFrom<=10000"></v-label-api>
      <span v-else>คลังชั่วคราว</span>
    </template>
    <template v-slot:item.stockTo="props">
      <v-label-api :api-url="['models','Stock','Stocks','find']" :api-key="props.item.stockTo" api-field="stockName" v-if="props.item.stockTo<=10000"></v-label-api>
      <span v-else>คลังชั่วคราว</span>
    </template>
    <template v-slot:item.action="props">
      <v-icon v-permission="'inventory-administrator'" class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)" v-if="!props.item.prescriptionsDispensingId && !props.item.encountersDispensingId && !props.item.stocksDispensingId">$vuetify.icons.delete</v-icon>
    </template>
  </v-model-table>
</template>

<script>
import { find,isEmpty } from 'lodash'

export default {
  data: ()=>({
    currentStocksProducts: undefined,
    headers: [
      {
        text: 'รายการวันที่',
        value: 'cardDateTime',
        class: 'body-2'
      },
      {
        text: 'ชนิดรายการ',
        value: 'cardType',
        class: 'body-2'
      },
      {
        text: 'สินค้า',
        value: 'productCode',
        class: 'body-2'
      },
      {
        text: 'Lot No.',
        value: 'lotNo',
        class: 'body-2'
      },
      {
        text: 'Expiry Date',
        value: 'expiryDate',
        class: 'body-2'
      },
      {
        text: 'จากคลัง',
        value: 'stockFrom',
        class: 'body-2'
      },
      {
        text: 'ไปคลัง',
        value: 'stockTo',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
      {
        text: 'รายละเอียดรายการ',
        value: 'description',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    cardType: [
      {text: 'รับเข้า', value: 'receive'},
      {text: 'จำหน่าย', value: 'dispensing'},
      {text: 'ย้ายคลัง', value: 'transfer'},
      {text: 'ปรับยอด', value: 'correction'},
      {text: 'รับคืน', value: 'return'},
    ],
  }),
  props: {
    stockId: {
      type: [String, Number],
      default: undefined,
    },
    hn: {
      type: String,
      default: undefined,
    },
    encounterId: {
      type: String,
      default: undefined,
    },
    prescriptionId: {
      type: [String, Number],
      default: undefined,
    },
    documentId: {
      type: [String, Number],
      default: undefined,
    },
    productCode: {
      type: String,
      default: undefined,
    },
    defaultCardType: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    initialData() {
      return {
        hn: this.hn,
        encounterId: this.encounterId,
        cardType: this.defaultCardType,
      }
    },
    computedFilter() {
      let filter = {
        '1%where#encounterId' : (isEmpty(this.encounterId)) ? undefined : this.encounterId,
        '2%where#productCode': this.productCode,
        '3%where#stockTo': this.stockId,
        '4%orWhere#stockFrom': this.stockId,
        '5%where#productCode': this.productCode,
        '6%where#encounterId' : (isEmpty(this.encounterId)) ? undefined : this.encounterId,
      }
      return filter
    }
  },
  methods: {
    cardTypeText(value) {
      let matchCardType = find(this.cardType,['value',value])
      return (matchCardType) ? matchCardType.text : value
    },
  }
}
</script>

<style lang="css" scoped>
</style>
