<template lang="html">
  <v-model-table model-name="Addresses" model-title="Addresses"
    :model-api="['models','Patient','PatientsAddresses']"
    :model-api-search="{'hn': hn}" :initial-data="{'hn': hn, 'country': 'TH', 'addressType': 'contact'}"
    :headers="headers" v-if="hn" v-bind="$attrs" v-on="$listeners">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-radio-group v-model="data.addressType" row>
              <v-radio label="ที่อยู่ติดต่อ" value="contact"></v-radio>
              <v-radio label="ที่อยู่ตามบัตรประชาชน" value="census"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-checkbox label="ที่อยู่ภายในประเทศ" v-model="data.country" true-value="TH" false-value=""></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-address v-model="data" :foreign-address="isForeignAddress(data.country)"></v-address>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.addressType="props">
      {{ addressTypeText(props.item.addressType) }}
    </template>
    <template v-slot:item.address="props">
      <v-label-address :address-data="props.item"></v-label-address>
    </template>
    <template v-slot:item.action="props">
      <v-icon class="mx-1" @click.stop="props.actions.editData(props.item)">$vuetify.icons.edit</v-icon>
      <v-icon class="mx-1" color="red darken-3" @click.stop="props.actions.deleteData(props.item)">$vuetify.icons.delete</v-icon>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { find } from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'ชนิดที่อยู่',
        value: 'addressType',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ที่อยู่',
        value: 'address',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    addressType: [
      {text:'ที่อยู่ตามบัตรประชาชน', value: 'census'},
      {text:'ที่อยู่ติดต่อ', value: 'contact'},
    ],
    foreignAddress: false,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  methods: {
    isForeignAddress(country) {
      if (country == 'TH') return false
      else return true
    },
    addressTypeText(type) {
      return (find(this.addressType,['value',type])) ? find(this.addressType,['value',type])['text'] : ""
    }
  }
}
</script>

<style lang="css">
</style>
