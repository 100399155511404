import api from 'tantee-common/lib/api'
import helperDateTime from 'tantee-common/lib/helperDateTime'
import * as helper from '@/modules/helperEncounter'
import { isUndefined,isNull } from 'lodash'

export function getEncounterById(encounterId) {
  
  if (!isUndefined(encounterId) && encounterId.length == 3) encounterId = helperDateTime.today().format('YYMMDD')+encounterId
  if (!isUndefined(encounterId) && encounterId.length == 9) encounterId = 'AMB'+encounterId

  return new Promise((resolve,reject)=>{
    if (!isUndefined(encounterId) && encounterId.length>0) {

      api.post(['models', 'Registration', 'Encounters', 'find'], { key: encounterId}).then((returnData)=>{
        if (returnData.success) {
          resolve(returnData.data)
        } else {
          reject(returnData.errorTexts)
        }
      }).catch((error)=>{
        reject(error)
      })
    } else {
      reject('Invalid Encounter ID input')
    }
  })
}

export default helper
