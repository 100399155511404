import { render, staticRenderFns } from "./vStocksCards.vue?vue&type=template&id=16e56e12&scoped=true&lang=html&"
import script from "./vStocksCards.vue?vue&type=script&lang=js&"
export * from "./vStocksCards.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16e56e12",
  null
  
)

export default component.exports