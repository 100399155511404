<template lang="html">
  <v-model-table model-name="Autocharges" model-title="รายการเรียกเก็บอัตโนมัติ"
    :model-api="['models','Registration','EncountersAutocharges']"
    :model-api-search="autochargesQuery"
    :initial-data="initialData"
    :headers="headers"
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container fluid pa-0>
        <v-row>
          <v-col cols="12" md="8">
            <v-master-field :rules="[rules.require()]" v-model="data.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field :rules="[rules.require(),rules.numeric()]" label="Qty" v-model="data.quantity"></v-text-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox v-model="data.isActive" label="เปิดใช้งาน"></v-checkbox>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field :rules="[rules.numeric()]" label="Repeat (Hours)" v-model="data.repeatHour"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field :rules="[rules.numeric()]" label="Round (Hours)" v-model="data.roundHour"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field :rules="[rules.numeric()]" label="Limit per Encounter" v-model="data.limitPerEncounter"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field :rules="[rules.numeric()]" label="Limit per Day" v-model="data.limitPerDay"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.productName="props">
      <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
    </template>
    <template v-slot:item.isActive="props">
      <v-icon class="text-center">{{ (props.item.isActive) ? 'check' : 'close' }}</v-icon>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
      },
      {
        text: 'รายการเรียกเก็บ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2',
      },
      {
        text: 'Repeat (hrs)',
        value: 'repeatHour',
        class: 'body-2',
      },
      {
        text: 'Round (hrs)',
        value: 'roundHour',
        class: 'body-2',
      },
      {
        text: 'Limit-Enc',
        value: 'limitPerEncounter',
        class: 'body-2',
      },
      {
        text: 'Limit-Day',
        value: 'limitPerDay',
        class: 'body-2',
      },
      {
        text: 'ใช้งาน',
        value: 'isActive',
        class: 'body-2',
        width: '100px',
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    encounterId: {
      type: String,
      default: undefined
    },
  },
  computed: {
    autochargesQuery() {
      return {
        encounterId: this.encounterId,
      }
    },
    initialData() {
      return {
        encounterId: this.encounterId,
        isActive: true
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
