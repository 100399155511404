<template lang="html">
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="5">
              <v-img src="@/assets/logo-with-text.svg"></v-img>
            </v-col>
            <v-col cols="11">
              <v-progress-linear indeterminate height="5" color="grey"></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
