<template lang="html">
  <v-card :flat="flat">
    <v-toolbar flat>
      <v-toolbar-title>
        {{ title }}
        <v-btn flat icon color="grey lighten-1" @click="loadData" v-if="refreshable">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-toolbar-items>

      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-form-dialog :max-width="dialogWidth" :max-height="dialogHeight" :fullscreen="dialogFullscreen"
        :form-title="title" :initialData="{hn : hn, photoType: 'patient_photo'}"
        @create="onCreateData"
        v-if="insertable"
      >
        <template v-slot="{data,rules,isCreating}">
          <v-image-capture v-model="data.base64data"></v-image-capture>
        </template>
      </v-form-dialog>
    </v-toolbar>
    <v-card-text v-if="!isLoading">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="2" v-for="(photoItem,index) in apiData[modelName]" :key="index">
            <v-patient-photo-item :photo="photoItem" @delete="deleteData(photoItem)"></v-patient-photo-item>
          </v-col>
          <v-col cols="12" v-if="!hasPhoto">
            <div align="center">
              <span class="headline">No photo</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-if="isLoading">
      <v-container fluid text-xs-center>
        <v-row>
          <v-col cols="12">
            <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="hasPagination">
      <v-spacer></v-spacer>
      <v-pagination :length="totalPages" v-model="currentPage"></v-pagination>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiCRUD from '@/components/mixins/apiCRUD'
import {isUndefined} from 'lodash'

export default {
  mixins: [apiCRUD],
  data: ()=>({
    totalPages: undefined,
    currentPage: 1,
    isLoading: false,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    perPage: {
      type: Number,
      default: 5
    },
    flat: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Patient Photos',
    },
    modelName: {
      type: String,
      default: 'patientPhoto'
    },
    insertable: {
      type: Boolean,
      default: true
    },
    refreshable: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: '80vw'
    },
    dialogHeight: {
      type: String,
      default: '80vh'
    },
    dialogFullscreen: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    reset() {
      this.totalPages = isUndefined
      this.currentPage = 1
    },
    onCreateData(formData,callback=undefined) {
      this.createAPI(this.modelName,formData).then(()=>{
        if (!isUndefined(callback)) callback.done()
      }).catch((errors) => {
        if (!isUndefined(callback)) callback.error(errors)
      })
    },
    deleteData(object) {
      this.$confirm('ต้องการที่จะลบรูปของผู้ป่วย').then((res)=>{
        if (res) {
          this.deleteAPI(this.modelName,object).then(()=>{
            this.loadData()
          })
        }
      })
    },
    loadData() {
      this.isLoading = true;

      this.searchAPI(this.modelName,{hn:this.hn},this.perPage,this.currentPage).then((returnData)=>{
        if (returnData.last_page) this.totalPages = returnData.last_page
        if (returnData.current_page) this.currentPage = returnData.current_page
      }).finally(()=>{
        this.isLoading = false
      })
    }
  },
  computed: {
    hasPagination() {
      if (isUndefined(this.totalPages) || this.totalPages<=1) {
        return false
      } else {
        return true
      }
    },
    hasPhoto() {
      if (isUndefined(this.apiData[this.modelName]) || this.apiData[this.modelName].length==0) {
        return false
      } else {
        return true
      }
    }
  },
  watch: {
    currentPage: function() {
      this.loadData()
    }
  },
  mounted() {
    this.apiCustomUrl.search = ['Patient','PatientPhotoController','getPhotos']
    this.apiCustomUrl.create = ['Patient','PatientPhotoController','addPhoto']
    this.apiCustomUrl.delete = ['Patient','PatientPhotoController','deletePhoto']
    this.apiCustomUrl.update = null
    this.loadData()
  }
}
</script>

<style lang="css" scoped>
</style>
