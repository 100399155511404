<template lang="html">
  <v-model-table model-name="Payers" model-title="Payers" model-key="payerCode"
    :model-api="['models','Master','Payers']" :headers="headers">
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row fluid>
          <v-col cols="12" md="3">
            <v-text-field :rules="[rules.require()]" v-model="data.payerCode" label="รหัส" :disabled="!isCreating"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :rules="[rules.require()]" v-model="data.payerName" label="ชื่อผู้ชำระค่าใช้จ่าย"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field :rules="[rules.numeric()]" v-model="data.creditPeriod" type="number" label="ระยะเวลาเครดิต" suffix="วัน"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="data.payerTelephoneNo" label="เบอร์โทรศัพท์"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="data.payerTaxNo" label="หมายเลขประจำตัวผู้เสียภาษี"></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="data.payerAddress" label="ที่อยู่"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-table-field v-model="data.overridePrices" title="กำหนดราคาพิเศษ" :headers="overridePricesHeaders" importable :insertable="false" show-create-pad auto-item-action>
              <template v-slot:item.productName="props">
                <v-label-api :api-url="['models','Master','Products','find']" :api-key="props.item.productCode" api-field="productName"></v-label-api>
              </template>
              <template v-slot:item.price="props">
                <v-edit-dialog
                  :return-value.sync="props.item.price"
                > 
                  {{ props.item.price }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.price"
                      type="number"
                      label="Price"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:createPad="{data: productData,rules}">
                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="8" md="10">
                      <v-master-field :rules="[rules.require()]" v-model="productData.productCode" :custom-api="['models','Master','Products','query']" custom-api-text="productName" custom-api-value="productCode" label="รายการ" show-code></v-master-field>
                    </v-col>
                    <v-col cols="4" md="2">
                      <v-text-field :rules="[rules.require(),rules.numeric()]" label="Price" v-model="productData.price"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-table-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัส',
        value: 'payerCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อผู้ชำระค่าใช้จ่าย',
        value: 'payerName',
        class: 'body-2'
      },
      {
        text: 'ที่อยู่',
        value: 'payerAddress',
        class: 'body-2'
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'payerTelephoneNo',
        class: 'body-2'
      },
      {
        text: 'TaxNo',
        value: 'payerTaxNo',
        class: 'body-2'
      },
      {
        text: 'เครดิต (วัน)',
        value: 'creditPeriod',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    overridePricesHeaders: [
      {
        text: 'รหัสรายการ',
        value: 'productCode',
        class: 'body-2',
      },
      {
        text: 'ชื่อรายการ',
        value: 'productName',
        class: 'body-2',
      },
      {
        text: 'ราคา',
        value: 'price',
        class: 'body-2',
      },
    ],
  })
}
</script>

<style lang="css" scoped>
</style>