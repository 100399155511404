import Store from '@/store'
import helperItem from '@/modules/helperItem'
import * as helper from '@/modules/helperPrescription'
import { isUndefined,isArray } from 'lodash'

export async function productCodeToText(productCode,isEnglish=false) {
  helperItem.findItem(['models', 'Master', 'Products'],productCode).then((returnData)=>{
    return (isEnglish) ? returnData.productNameEN : returnData.productName
  }).catch((e)=>{
    void e
    return ''
  })
}

export async function directionsToText(directions, isEnglish=false) {
  if (isArray(directions)) {
    let directionsTextArray = []
    for (const direction of directions) {
      let tmpDirectionArray = []
      if (direction.route) tmpDirectionArray.push(await Store.getters.getItemValue('medicineRoute', direction.route, isEnglish))
      if (direction.amount) tmpDirectionArray.push(direction.amount)
      if (direction.unit) tmpDirectionArray.push(await Store.getters.getItemValue('unit', direction.unit, isEnglish))
      if (direction.frequency) tmpDirectionArray.push(await Store.getters.getItemValue('medicineFrequency', direction.frequency, isEnglish))
      if (direction.times) tmpDirectionArray.push(await Store.getters.getItemValue('medicineTimes', direction.times, isEnglish))
      directionsTextArray.push(tmpDirectionArray.join(' '))
    }
    return directionsTextArray.join('\n')
  } else {
    return (directions) ? directions : ''
  }
}

export async function cautionsToText(cautions, isEnglish=false) {
  if (isArray(cautions)) {
    let cautionsTextArray = []
    for (const caution of cautions) {
      let tmpCaution = await Store.getters.getItemValue('medicineCaution', caution, isEnglish)
      
      cautionsTextArray.push(tmpCaution)
    }
    return cautionsTextArray.join(',')
  } else {
    return (cautions) ? cautions : ''
  }
}

export default helper
