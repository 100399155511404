export default {
  namePrefix: '$NamePrefix',
  nameSuffix: '$NameSuffix',
  maritalStatus: '$MaritalStatus',
  occupation: '$Occupation',
  religion: '$Religion',
  nationality: '$Nationality',
  addressSubdistrict: '$Subdistrict',
  addressDistrict: '$District',
  addressProvince: '$Province',
  addressCountry: '$Country',
  addreesPostCode: '$PostCode',
  productCategory: '$ProductCategory',
  productCategoryInsurance: '$ProductCategoryInsurance',
  productCategoryCgd: '$ProductCategoryCgd',
  unit: '$Unit',
  medicineMimsClass: '$MedicineMimsClass',
  medicineRoute: '$MedicineRoute',
  medicineFrequency: '$MedicineFrequency',
  medicineTimes: '$MedicineTimes',
  medicineCaution: '$MedicineCaution',
  documentCategory: '$DocumentCategory',
  doctorType: '$DoctorType',
  doctorSpecialty: '$DoctorSpecialty',
  appointmentType: '$AppointmentType',
  appointmentActivity: '$AppointmentActivity',
  payerType: '$PayerType',
  icd10: '$ICD10',
  reportingType: '$ReportingType'
}