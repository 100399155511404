<template>
  <v-list>
    <v-menu-item :MenuItems="MainMenuItems"></v-menu-item>
  </v-list>
</template>
<script>
  import {unionBy} from 'lodash'
  
  export default {
    data: () => ({
      defaultMenu: []
    }),
    computed: {
      MainMenuItems() {
        const requireMenu = require.context(
          '@/views',
          true,
          /module.js$/
        )

        requireMenu.keys().forEach(fileName => {
          const module = requireMenu(fileName)
          if (module.moduleMenu) this.defaultMenu = unionBy(this.defaultMenu, module.moduleMenu, 'title')
        })

        return this.defaultMenu
      }
    }
  }
</script>
<style lang="css">

</style>
