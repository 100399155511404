<template lang="html">
  <v-info-dialog form-title="ประวัติการแพ้ยา" ref="allergiesDialog">
    <template v-slot:activateButton="{on,disabled}">
      <v-badge
        color="red lighten-1"
        :content="allergiesCount"
        overlap bordered
        :value="allergiesCount"
      >
        <v-btn v-on="on" :disabled="disabled" :color="iconColor" icon large><v-icon>mdi-pill</v-icon></v-btn>
      </v-badge>
    </template>
    <template v-slot:content>
      <v-enhance-table :items="allergiesData" :headers="headers">
      </v-enhance-table>
    </template>
  </v-info-dialog>
</template>

<script>
import helperItem from '@/modules/helperItem'
import { find } from 'lodash'

export default {
  data: ()=>({
    allergiesData: undefined,
    headers: [
      {
        text: "ชนิดการแพ้",
        value: "allergyType",
        class: "body-2",
        width: "150px",
      },
      {
        text: "สิ่งที่แพ้",
        value: "suspectedProduct",
        class: "body-2",
      },
      {
        text: "ความน่าจะเป็น",
        value: "probability",
        class: "body-2",
        width: "150px",
      },
      {
        text: "ความรุนแรง",
        value: "severity",
        class: "body-2",
        width: "150px",
      },
      {
        text: "อาการ",
        value: "manifestation",
        class: "body-2",
      },
    ]
  }),
  computed: {
    iconColor() {
      return (this.allergiesData && this.allergiesData.length>0) ? 'warning' : 'primary'
    },
    allergiesCount() {
      return (this.allergiesData) ? this.allergiesData.length : 0
    }
  },
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  methods: {
    loadAllergies() {
      helperItem.searchItem(['models','Patient','PatientsAllergies'],{hn: this.hn}).then((returnData)=>{
        this.allergiesData = returnData
        let severe = find(returnData,{severity: 'high'})
        if (severe) this.$refs.allergiesDialog.showDialog()
      }).catch((e)=>{
        void e
        this.allergiesData = undefined
      })
    },
  },
  watch: {
    hn: function () {
      this.loadAllergies()
    }
  },
  mounted() {
    this.loadAllergies()
  }
}
</script>

<style lang="css" scoped>
</style>
