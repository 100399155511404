<template lang="html">
  <v-model-table model-name="StocksProducts" model-title="ตะกร้ายา"
    :model-api="['models','Stock','StocksProducts']"
    :model-api-search="stockProductQuery"
    api-scope="nonZero"
    :headers="headers"
    :insertable="false"
    group-by="productCode"
    searchable
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:group.header="props">
      <td :colspan="props.headers.length-1">
        <label class="font-weight-bold">{{ props.group }} {{ props.items[0].product.productName }}</label>
      </td>
      <td colspan="1">
        <v-info-dialog form-title="รายการความเคลื่อนไหว">
          <template v-slot:activateButton="button">
            <v-icon v-on="button.on" :disabled="button.disabled">mdi-animation-play-outline</v-icon>
          </template>
          <template v-slot:content>
            <v-stocks-cards :stockId="99999" :encounterId="encounterId" :productCode="props.items[0].productCode" flat :insertable="false"></v-stocks-cards>
          </template>
        </v-info-dialog>
      </td>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสสินค้า',
        value: 'productCode',
        class: 'body-2',
        width: '150px'
      },
      {
        text: 'ชื่อสินค้า',
        value: 'product.productName',
        class: 'body-2'
      },
      {
        text: 'Lot No.',
        value: 'lotNo',
        class: 'body-2'
      },
      {
        text: 'Expiry Date',
        value: 'expiryDate',
        class: 'body-2'
      },
      {
        text: 'จำนวน',
        value: 'quantity',
        class: 'body-2'
      },
    ],
  }),
  props: {
    encounterId: {
      type: String,
      default: undefined,
    }
  },
  computed: {
    stockProductQuery() {
      return {
        stockId: '99999',
        encounterId: this.encounterId,
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
