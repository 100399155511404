export let moduleMenu = [
  {
    title: 'งานเบิกจ่ายค่ารักษาพยาบาล',
    action: 'mdi-currency-usd',
    menuItems: [
      {
        title: 'รายการค่ารักษาพยาบาลตามสิทธิ์',
        action: 'mdi-format-list-bulleted-type',
        link: { name: 'reimbursement.itemize' },
        permission: 'reimbursement-itemize',
      },
    ],
    permission: 'reimbursement',
  },
]

export let moduleRoute = [
  {
    path: '/reimbursement/Itemize',
    name: 'reimbursement.itemize',
    component: () => import('./Itemize')
  },
]