<template lang="html">
  <v-card color="white">
    <v-card-text v-if="currentPatient" class="pa-2">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="12" sm="2" md="1" align-self="center" class="text-center pa-0">
            <v-patient-photo :hn="currentPatient.hn" avatar avatar-size="60"></v-patient-photo>
          </v-col>
          <v-col>
            <v-row dense>
              <v-col cols="12" sm="4" md="2" align-self="center" class="font-weight-bold display-1 text-truncate">
                {{ currentPatient.hn }}
              </v-col>
              <v-col align-self="center" class="display-1 text-truncate">
                <v-label-name :name-data="currentPatient.name_th"></v-label-name>
              </v-col>
              <v-col cols="12" sm="6" md="2" align-self="center" class="headline text-truncate">
                <v-label-age :date-of-birth="currentPatient.dateOfBirth"></v-label-age>
              </v-col>
              <v-col cols="11" sm="6" md="3" align-self="center" class="headline text-truncate">
                วันเกิด <v-label-datetime :date-time="currentPatient.dateOfBirth" short-date></v-label-datetime>
              </v-col>
              <v-col md="auto">
                <v-patient-messages-icon :hn="currentPatient.hn"></v-patient-messages-icon>
                <v-patient-insurances-icon :hn="currentPatient.hn"></v-patient-insurances-icon>
                <v-patient-allergies-icon :hn="currentPatient.hn"></v-patient-allergies-icon>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="auto" align-self="center" class="headline text-truncate">
            <v-btn icon @click="reset"><v-icon>close</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-container v-else pt-0 fluid>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-patient-select @patientChange="searchHN" :loading="isLoading" :disabled="isLoading"></v-patient-select>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import patient from '@/modules/helperPatient'
import { isUndefined } from 'lodash'

export default {
  data: () => ({
    currentPatient: undefined,
    isLoading: false,
  }),
  props: {
    value: {
      type: String,
      default: undefined
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal) this.loadHN(newVal)
    }
  },
  methods: {
    searchHN(hn) {
      if (!isUndefined(hn) && hn.length>0) {
        this.isLoading = true
        this.loadHN(hn).then((returnData)=>{
          this.$emit('input',returnData.hn)
        }).catch(()=>{
          this.reset()
        }).finally(()=>{
          this.isLoading = false
        })
      } else {
        this.reset()
      }
    },
    loadHN(hn) {
      return new Promise((resolve,reject)=>{
        patient.getPatientByHn(hn).then((returnData)=>{
          this.currentPatient = returnData
          resolve(returnData)
          this.$emit('patientLoaded',returnData)
        }).catch((error)=>{
          reject(error)
          this.reset()
        })
      })
    },
    reset() {
      this.$emit('input',null)
      this.$emit('patientUnloaded')
      this.currentPatient = undefined
      this.isLoading = false
    }
  },
  beforeMount() {
    if (!isUndefined(this.value) && this.value!==null) this.loadHN(this.value)
  },
}
</script>

<style lang="css">
</style>
