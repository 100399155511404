export let moduleMenu = [
  {
    title: 'Registration',
    action: 'folder_shared',
    menuItems: [
      {
        title: 'New Patient',
        action: 'person_add',
        link: { name: 'registration.patient.new' },
        permission: 'registration-patient-new',
      },
      {
        title: 'Patient Information',
        action: 'person',
        link: { name: 'registration.patient' },
        permission: 'registration-patient-edit'
      },
      {
        title: 'Payer and Insurances',
        action: 'monetization_on',
        link: { name: 'registration.patient.insurance' },
        permission: 'registration-patient-insurance',
      },
      {
        title: 'Appointment',
        action: 'today',
        link: { name: 'registration.appointment' },
        permission: 'registration-patient-appointment',
      },
      {
        title: 'Encounter Register',
        action: 'mdi-location-enter',
        link: { name: 'registration.encounter.register' },
        permission: 'registration-encounter',
      },
    ],
    permission: 'registration',
  },
]

export let moduleRoute = [
  {
    path: '/registration/NewPatient',
    name: 'registration.patient.new',
    component: () => import(/* webpackChunkName: "NewPatient" */ './NewPatient')
  },
  {
    path: '/registration/Patient',
    name: 'registration.patient',
    component: () => import('./Patient')
  },
  {
    path: '/registration/PatientInsurance',
    name: 'registration.patient.insurance',
    component: () => import('./PatientInsurance')
  },
  {
    path: '/registration/Appointment',
    name: 'registration.appointment',
    component: () => import('./Appointment')
  },
  {
    path: '/registration/EncounterRegister',
    name: 'registration.encounter.register',
    component: () => import('./EncounterRegister')
  },
  {
    path: '/registration/PatientPhoto',
    name: 'patientphoto',
    component: () => import('./PatientPhoto')
  },
]