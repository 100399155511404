<template lang="html">
  <v-model-table model-name="Allergies" model-title="ประวัติการแพ้"
    :model-api="['models','Patient','PatientsAllergies']"
    :model-api-search="allergiesQuery"
    :initial-data="initialData"
    :headers="headers"
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row fluid>
          <v-col cols="12" md="2">
            <v-master-field :rules="[rules.require()]" v-model="data.allergyType" label="ชนิดการแพ้" groupKey="$AllergyType"></v-master-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field :rules="[rules.require()]" v-model="data.suspectedProduct" label="สิ่งที่แพ้"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.suspectedGPU" label="TMT GPU"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-master-field :rules="[rules.require()]" v-model="data.probability" label="ความน่าจะเป็น" groupKey="$AllergyProbability"></v-master-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-master-field :rules="[rules.require()]" v-model="data.severity" label="ความรุนแรง" groupKey="$AllergySeverity"></v-master-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-master-field :rules="[rules.require()]" v-model="data.informationSource" label="แหล่งที่มา" groupKey="$AllergyInformationSource"></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox v-model="data.isNewOccurenceDate" label="รายการเกิดการแพ้ใหม่"></v-checkbox>
          </v-col>
          <v-col cols="12" md="3" v-if="data.isNewOccurenceDate">
            <v-date-field v-model="data.isNewOccurenceDate" label="วันที่เกิดการแพ้ใหม่"></v-date-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea :rules="[rules.require()]" v-model="data.manifestation" label="อาการ" auto-grow></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: "ชนิดการแพ้",
        value: "allergyType",
        class: "body-2",
        width: "150px",
      },
      {
        text: "สิ่งที่แพ้",
        value: "suspectedProduct",
        class: "body-2",
      },
      {
        text: "TMT GPU",
        value: "suspectedGPU",
        class: "body-2",
        width: "150px",
      },
      {
        text: "ความน่าจะเป็น",
        value: "probability",
        class: "body-2",
        width: "150px",
      },
      {
        text: "ความรุนแรง",
        value: "severity",
        class: "body-2",
        width: "150px",
      },
      {
        text: "อาการ",
        value: "manifestation",
        class: "body-2",
      },
      {
        text: "Action",
        value: "action",
        align: "center",
        width: "350px",
        sortable: false,
        class: "body-2",
      },
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  computed: {
    initialData() {
      return {
        hn: this.hn
      }
    },
    allergiesQuery() {
      return {
        hn: this.hn
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
