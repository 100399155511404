<template lang="html">
  <label>
    {{ nameText }}
  </label>
</template>

<script>
import {isUndefined,isArray} from 'lodash'
import patient from '@/modules/helperPatient'

export default {
  data: ()=>({
    nameText : null,
  }),
  props: {
    nameData: {
      type: [Object, Array],
      default: undefined
    },
    shortName: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    nameData: {
      handler() {
        this.loadName()
      },
      deep: true
    },
    shortName: function(newVal) {
      this.loadName()
    }
  },
  methods: {
    loadName() {
      if (!isUndefined(this.nameData)) {
        patient.name((isArray(this.nameData)) ? this.nameData[0] : this.nameData,this.shortName).then((returnData)=>{
          this.nameText = returnData
        })
      }
    }
  },
  mounted() {
    this.loadName()
  }
}
</script>

<style lang="css">
</style>
