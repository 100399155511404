<template lang="html">
  <v-dialog :fullscreen="fullscreen" :scrollable="!fullscreen" :value="true">
    <v-card v-if="documentStack.length">
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon>person</v-icon> {{documentStack[currentIndex].patient.hn}} <v-label-name :name-data="documentStack[currentIndex].patient.name_th"></v-label-name> 
          <v-icon>mdi-file-document</v-icon> {{documentStack[currentIndex].template.templateName}}
          <v-icon>mdi-calendar-month</v-icon> {{ documentStack[currentIndex].created_at }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="documentStack.length>1">
          <v-btn icon @click="currentIndex=currentIndex-1" :disabled="currentIndex==0"><v-icon>mdi-chevron-left</v-icon></v-btn>
        </v-toolbar-items>
        <v-toolbar-items v-if="documentStack.length>1">
          <v-btn icon @click="currentIndex=currentIndex+1" :disabled="currentIndex>=documentStack.length-1"><v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn icon @click="printDocument(documentStack[currentIndex].id)"><v-icon>print</v-icon></v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn icon @click="closeViewer"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-sheet height="90vh" v-if="documentStack[currentIndex].isScanned">
        <viewer 
          :options="viewerOptions"
          :images="itemsSrc(documentStack[currentIndex])"
          v-if="documentStack[currentIndex]['isScanned'] && !documentStack[currentIndex]['isPdf']"
        >
          <img v-for="src in itemsSrc(documentStack[currentIndex])" :src="src" :key="src" style="display:none;">
        </viewer>
        <object :data="itemsSrc(documentStack[currentIndex])[0]+'#toolbar=0'" type="application/pdf" width="100%" height="100%" v-if="documentStack[currentIndex]['isScanned'] && documentStack[currentIndex]['isPdf']">
        </object>
      </v-sheet>
      <v-card-text v-if="!documentStack[currentIndex].isScanned">
        <v-document-editor :documentId="documentStack[currentIndex].id" read-only flat></v-document-editor>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { isUndefined,isArray } from 'lodash'
import 'viewerjs/dist/viewer.css'
import api from 'tantee-common/lib/api'
import Viewer from 'v-viewer'
import Vue from 'vue'
import printDocument from '@/components/mixins/printDocument'

Vue.use(Viewer)
Viewer.setDefaults({
  zIndexInline: 2017
})

export default {
  mixins: [printDocument],
  data: ()=>({
    documentStack: [],
    currentIndex: 0,
  }),
  props: {
    documents: {
      type: [Object,Array],
      default: ()=>({})
    },
    fullscreen: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeViewer() {
      this.$destroy()
    },
    itemsSrc(document) {
      let src = []
      if (document.isScanned) {
        document.data.forEach((item)=>{
          src.push(api.urlBuilder(['assets',item['id']]))
        })
      }
      return src
    },
  },
  computed: {
    viewerOptions() {
      return {  
        inline:true, 
        button:false, 
        title: false, 
        tooltip: false, 
        movable: true,
        toolbar: { 
          play: false, 
          prev: this.documentStack[this.currentIndex] && this.documentStack[this.currentIndex].data && this.documentStack[this.currentIndex].data.length > 1, 
          next: this.documentStack[this.currentIndex] && this.documentStack[this.currentIndex].data && this.documentStack[this.currentIndex].data.length > 1, 
          zoomIn: true, 
          zoomOut: true, 
          oneToOne: true, 
          rotateLeft: true,
          rotateRight: true
        },
      }
    }
  },
  watch: {
    documents: {
      handler(newVal) {
        this.documentStack = (isArray(newVal)) ? newVal : [newVal]
        this.currentIndex = 0
      },
      deep: true
    }
  },
  mounted() {
    this.documentStack = (isArray(this.documents)) ? this.documents : [this.documents]
    this.currentIndex = 0
  }
}
</script>

<style lang="css" scoped>
</style>
