<template lang="html">
  <v-dialog @input="change" value="true" max-width="80vw" max-height="80vh" @keydown.esc="cancel">
    <v-card>
      <v-form @submit.prevent="search">
      <v-toolbar flat>
        <v-date-field v-model="criteria.admitDateTime" label="วันที่" single-line hide-details @input="search"></v-date-field>
        <v-patient-select v-model="criteria.hn" class="px-1" label="HN" single-line hide-details @patientChange="search"></v-patient-select>
        <v-master-field v-model="criteria.clinicCode" :custom-api="['models','Master','Clinics','query']"
          custom-api-text="clinicName" custom-api-value="clinicCode"
          customApiEagerLoad
          label="คลินิก/ชนิดเตียง" show-code
          class="px-1" single-line hide-details clearable
          @input="search"
        ></v-master-field>
        <v-master-field v-model="criteria.doctorCode" :custom-api="['models','Master','Doctors','query']"
          custom-api-text="nameTH" custom-api-value="doctorCode"
          customApiEagerLoad
          label="แพทย์" show-code
          class="px-1" single-line hide-details clearable
          @input="search"
        ></v-master-field>
        <v-spacer></v-spacer>
        <v-btn icon type="submit">
          <v-icon>search</v-icon>
        </v-btn>
      </v-toolbar>
      </v-form>
      <v-card-text class="pa-0">
        <v-model-table
          v-model="selectedItem"
          model-name="Encounters"
          model-key="encounterId"
          :model-api="['models','Registration','Encounters']"
          :model-api-search="searchFilter"
          api-order-by="encounterId"
          :headers="headers"
          hide-toolbar
          flat
          show-select
        >
          <template v-slot:item.patient.name_th="{item}">
            <v-label-name :name-data="item.patient.name_th"></v-label-name>
          </template>
        </v-model-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      <v-btn color="primary" @click="choose" :disabled="!selectedItem.length">เลือก</v-btn>
        <v-btn color="grey" @click="cancel">ยกเลิก</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {isUndefined,isEmpty} from 'lodash'
import helperDateTime from 'tantee-common/lib/helperDateTime'

export default {
  data: ()=>({
    value: false,
    selectedItem: [],
    searchFilter: null,
    criteria: {
      admitDateTime: helperDateTime.today().format('YYYY-MM-DD')
    },
    headers: [
      {
        text: 'Encounter ID',
        value: 'encounterId',
        class: 'body-2'
      },
      {
        text: 'วันที่',
        value: 'admitDateTime',
        class: 'body-2'
      },
      {
        text: 'HN',
        value: 'hn',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'คลินิก',
        value: 'clinic.clinicName',
        class: 'body-2'
      },
      {
        text: 'แพทย์',
        value: 'doctor.nameTH',
        class: 'body-2'
      },
    ]
  }),
  props: {
    initialFilter: {
      type: Array,
      default: undefined
    }
  },
  methods: {
    choose() {
      if (this.selectedItem.length>0) {
        this.$emit('result', this.selectedItem[0].encounterId)
        this.value = this.selectedItem[0].encounterId
      } else {
        this.$emit('result', false)
        this.value = false
      }
      this.$destroy()
    },
    cancel() {
      this.$destroy()
    },
    change (res) {
      this.$destroy()
    },
    search() {
      let tmpFilter = (isUndefined(this.initialFilter)) ? [] : this.initialFilter.slice()
      
      if (!isEmpty(this.criteria.hn)) tmpFilter.push(['hn','=',this.criteria.hn])
      if (!isEmpty(this.criteria.admitDateTime)) tmpFilter.push(['whereDate#admitDateTime','=',this.criteria.admitDateTime])
      if (!isEmpty(this.criteria.clinicCode)) tmpFilter.push(['clinicCode','=',this.criteria.clinicCode])
      if (!isEmpty(this.criteria.doctorCode)) tmpFilter.push(['doctorCode','=',this.criteria.doctorCode])

      if (tmpFilter.length > 0) {
        this.searchFilter = tmpFilter
      } else {
        this.searchFilter = null
      }
    },
  },
}
</script>

<style lang="css">
</style>
