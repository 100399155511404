<template lang="html">
  <v-form-pad ref="nameForm" v-model="nameData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row dense>
          <v-col cols="12" md="2">
            <v-master-field v-model="data.namePrefix" :label="(english) ? 'Title' : 'คำนำหน้า'"
              :rules="[rules.requireIf(required && !disabled,'required')]"
              :groupKey="$store.getters.getGroupKey('namePrefix')"
              :english="english"
              :disabled="disabled" clearable></v-master-field>
          </v-col>
          <v-col cols="5" md="3">
            <v-text-field v-model="data.firstName" :label="(english) ? 'Firstname' : 'ชื่อ'"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" :readonly="readonly"></v-text-field>
          </v-col>
          <v-col cols="2" md="2">
            <v-text-field v-model="data.middleName" :label="(english) ? 'Middlename' : 'ชื่อกลาง'"
              :disabled="disabled" :readonly="readonly"></v-text-field>
          </v-col>
          <v-col cols="5" md="3">
            <v-text-field v-model="data.lastName" :label="(english) ? 'Lastname' : 'นามสกุล'"
              :rules="[rules.requireIf(required && !disabled)]"
              :disabled="disabled" :readonly="readonly"></v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-master-field  v-model="data.nameSuffix" :label="(english) ? 'Suffix' : 'คำลงท้าย'"
              :groupKey="$store.getters.getGroupKey('nameSuffix')"
              :english="english"
              :disabled="disabled" clearable></v-master-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import { isUndefined } from 'lodash'
import Vue from 'vue'

export default {
  data: ()=>({
    nameData: {},
  }),
  props: {
    nameType: {
      type: String,
      default: null
    },
    value: {
      type: Object,
      default: () => ({})
    },
    english: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      handler() {
        this.loadName()
      },
      deep: true
    },
    nameData: {
      handler(newVal) {
        this.$emit("input",newVal)
      },
      deep: true
    },
  },
  methods: {
    loadName() {
      let tempName = this.value
      if (this.nameType!=null) {
        tempName.nameType = this.nameType
      }
      this.nameData = tempName
    },
  },
  mounted() {
    this.loadName()
  },
}
</script>

<style lang="css">
</style>
