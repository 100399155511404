<template lang="html">
  <v-info-dialog form-title="สิทธิ์การรักษา" ref="insurancesDialog">
    <template v-slot:activateButton="{on,disabled}">
      <v-btn v-on="on" :disabled="disabled" :color="iconColor" icon large><v-icon>mdi-shield-account</v-icon></v-btn>
    </template>
    <template v-slot:content>
      <v-enhance-table :items="insurancesData" :headers="headers">
        <template v-slot:item.payer="props">
          <v-label-master group-key="payerType" :item-code="props.item.payerType"></v-label-master>
          <label v-if="props.item.payer">({{props.item.payer.payerName}})</label>
        </template>
        <template v-slot:item.policies="props">
          <div v-for="item in props.item.policies" :key="item.id">
            <span class="font-weight-bold">{{ item.insuranceCode }}</span>
            <v-label-api :api-url="['models','Master','Insurances','find']" :api-key="item.insuranceCode" api-field="insuranceName">
              <template v-slot="props">
                {{props.item.insuranceName}}
                <span v-if="props.item.isApplyToOpd" class="font-weight-bold">[ผู้ป่วยนอก]</span>
                <span v-if="props.item.isApplyToIpd" class="font-weight-bold">[ผู้ป่วยใน]</span>
              </template>
            </v-label-api>
          </div>
        </template>
        <template v-slot:item.beginDate="props">
          <v-label-datetime short-date :date-time="props.item.beginDate"></v-label-datetime>
        </template>
        <template v-slot:item.endDate="props">
          <v-label-datetime short-date :date-time="props.item.endDate"></v-label-datetime>
        </template>
        <template v-slot:item.isChargeToPatient="props">
          <v-icon class="text-center">{{ (props.item.isChargeToPatient) ? 'check' : 'close' }}</v-icon>
        </template>
      </v-enhance-table>
    </template>
  </v-info-dialog>
</template>

<script>
import helperItem from '@/modules/helperItem'

export default {
  data: ()=>({
    insurancesData: undefined,
    headers: [
      {
        text: 'ลำดับ',
        value: 'priority',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'สิทธิ์/ประกัน',
        value: 'payer',
        class: 'body-2',
      },
      {
        text: 'นโยบาย',
        value: 'policies',
        class: 'body-2',
      },
      {
        text: 'เริ่มต้น',
        value: 'beginDate',
        class: 'body-2'
      },
      {
        text: 'สิ้นสุด',
        value: 'endDate',
        class: 'body-2'
      },
      {
        text: 'สำรองจ่าย',
        value: 'isChargeToPatient',
        class: 'body-2',
        width: '100px'
      },
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    clinicCode: {
      type: String,
      default: undefined
    }
  },
  computed: {
    iconColor() {
      return (this.insurancesData && this.insurancesData.length>0) ? 'green darken-2' : 'primary'
    },
  },
  methods: {
    loadInsurances() {
      helperItem.searchItem(['models','Patient','PatientsInsurances'],{hn: this.hn},undefined,undefined,'activeClinic',this.clinicCode).then((returnData)=>{
        this.insurancesData = returnData
      }).catch((e)=>{
        void e
        this.insurancesData = undefined
      })
    },
  },
  watch: {
    hn: function () {
      this.loadInsurances()
    },
    clinicCode: function () {
      this.loadInsurances()
    }
  },
  mounted() {
    this.loadInsurances()
  }
}
</script>

<style lang="css" scoped>
</style>
