<template lang="html">
  <v-form-pad ref="productMedicine" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="12" md="6">
            <v-master-field v-model="data.mimsClass" label="MIMS Class"
              groupKey="medicineMimsClass"
              :rules="[rules.require()]"
              clearable
              show-code
            ></v-master-field>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox v-model="data.isHighAlertDrug" label="High alert drug"></v-checkbox>
          </v-col>
          <v-col cols="6" md="2">
            <v-checkbox v-model="data.isLASADrug" label="LASA drug"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.tmtTpuCode" :rules="[rules.require()]" label="TMT TPU Code"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.tmtGpCode" :rules="[rules.require()]" label="TMT GP Code"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.rxnormCode" label="RxNorm Code"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-master-field v-model="data.allowedRoute" label="Allowed route"
              groupKey="medicineRoute"
              :rules="[rules.require()]"
              clearable
              multiple
            ></v-master-field>
          </v-col>
          <v-col cols="8" md="4">
            <v-text-field v-model="data.packageSize" :rules="[rules.numeric()]" label="Package size"></v-text-field>
          </v-col>
          <v-col cols="4" md="2">
            <v-master-field v-model="data.packageUnit" :rules="[rules.requireIf(data.packageSize)]" label="Package unit"
              groupKey="unit"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="4" md="2">
            <v-master-field v-model="data.defaultRoute" label="Default route"
              :showOnly="data.allowedRoute"
              groupKey="medicineRoute"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="4" md="2">
            <v-text-field v-model="data.defaultAmount" :rules="[rules.numeric()]" label="Default amount"></v-text-field>
          </v-col>
          <v-col cols="4" md="2">
            <v-master-field v-model="data.defaultUnit" label="Default unit"
              groupKey="unit"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-master-field v-model="data.defaultFrequency" label="Default frequency"
              groupKey="medicineFrequency"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-master-field v-model="data.defaultTimes" label="Default times"
              groupKey="medicineTimes"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="12">
            <v-master-field v-model="data.defaultCaution" label="Default caution"
              groupKey="medicineCaution"
              clearable
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="data.prescribedPermission" label="Permission"></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="data.prescribedDocument" label="Documents"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
