<template lang="html">
  <v-model-table model-name="Vouchers" model-title="รายการ Voucher"
    :model-api="['models','Registration','EncountersVouchers']"
    :model-api-search="voucherQuery"
    :initial-data="initialData"
    :headers="headers"
    :dialog-width="($vuetify.breakpoint.mdAndUp) ? '50vw' : '80vw'"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="8">
            <v-master-field :rules="[rules.require()]" v-model="data.voucherId" 
              :custom-api="['models','Master','Vouchers','query']" custom-api-text="voucherName" 
              custom-api-value="id" label="ชนิด Voucher"
              customApiEagerLoad
            ></v-master-field>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="data.voucherNumber" label="หมายเลขบัตร"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:item.voucherName="props">
      <v-label-api :api-url="['models','Master','Vouchers','find']" :api-key="props.item.voucherId" api-field="voucherName"></v-label-api>
    </template>
  </v-model-table>
</template>

<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'Voucher Name',
        value: 'voucherName',
        class: 'body-2'
      },
      {
        text: 'หมายเลขบัตร',
        value: 'voucherNumber',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  props: {
    encounterId: {
      type: String,
      default: undefined
    },
  },
  computed: {
    voucherQuery() {
      return {
        encounterId: this.encounterId,
      }
    },
    initialData() {
      return {
        encounterId: this.encounterId,
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
