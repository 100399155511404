<template lang="html">
  <label>
    {{ addressText }}
  </label>
</template>

<script>
import {isUndefined} from 'lodash'
import patient from '@/modules/helperPatient'

export default {
  data: ()=>({
    addressText : null,
  }),
  props: {
    addressData: {
      type: [Object, Array],
      default: undefined
    }
  },
  watch: {
    addressData: {
      handler() {
        this.loadAddress()
      },
      deep: true
    }
  },
  methods: {
    loadAddress() {
      if (!isUndefined(this.addressData)) {
        patient.address(this.addressData).then((returnData)=>{
          this.addressText = returnData
        })
      }
    }
  },
  mounted() {
    this.loadAddress()
  }
}
</script>

<style lang="css">
</style>
