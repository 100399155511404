<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <component :is="CurrentScreen"></component>
    </transition>
  </v-app>
</template>

<script>
import api from 'tantee-common/lib/api'
import MainScreen from '@/views/MainScreen'
import LoginScreen from '@/views/LoginScreen'
import LoadingScreen from '@/views/LoadingScreen'
import helperClient from '@/modules/helperClient'
import config from '@/config'
import groupKey from '@/groupKey'

export default {
  name: 'App',
  data: () => ({
    isReady: false,
    CurrentScreen : LoadingScreen
  }),

  computed: {
    systemReady: function() {
      return this.isReady
    },
  },
  methods: {
    checkLogin() {
      return new Promise((resolve)=>{
        this.$userCheck(true).then((result)=>{
          resolve(result)
        })
      })
    }
  },
  mounted () {
    this.$store.commit('addDefaultConfig',{
      configName: 'api',
      configItems: config.api
    })
    this.$store.commit('addDefaultConfig',{
      configName: 'locale',
      configItems: config.locale
    })
    
    helperClient.clientStamp().then(()=>{
      helperClient.loadMachineConfig().then(()=>{
        //override config for api.authentication , api.default, api.pacs
        delete config.api.localDevice

        this.$store.commit('addConfig',{
          configName: 'api',
          configItems: config.api
        })

        helperClient.dumpMachineConfig()

        this.$store.commit('addGroupKey',groupKey);
        let masterToLoad = [
          {groupKey : '$NamePrefix'},
          {groupKey : '$NameSuffix'},
          {groupKey : '$Subdistrict'},
          {groupKey : '$District'},
          {groupKey : '$Province'},
          {groupKey : '$ICD10'},
        ]
        let masterLoadPromise = []

        masterToLoad.forEach((master)=>{
          masterLoadPromise.push(this.$store.dispatch('LoadMasters',master))
        })
        Promise.all(masterLoadPromise).then(()=>{
          this.CurrentScreen = LoginScreen
          this.checkLogin().then((result)=>{
            this.CurrentScreen = MainScreen
          })
        })

        api.get('/version.txt').then((returnData)=>{
          if (returnData.success && returnData.data && (returnData.data.length <= 30)) {
            this.CurrentVersion = returnData.data
            setInterval(()=>{
              api.get('/version.txt').then((returnData)=>{
                if ((this.CurrentVersion != returnData.data) && this.isAppIdle) {
                  location.reload();
                }
              }).catch((e)=>{
                void e
              })
            },1000*60*5)
          }
        }).catch((e)=>{
          void e
        })
      })
    })
  }
}
</script>

<style lang="css">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
  aside {
    display: none !important;
    width: 0px !important;
  }
  main {
    padding: 0px !important;
  }
}
</style>
