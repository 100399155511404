export let moduleMenu = [
  {
    title: 'User Configuration',
    action: 'supervisor_account',
    menuItems: [
      {
        title: 'User Management',
        action: 'person_add',
        link: { name: 'config.users' },
        permission: 'user-management',
      },
      {
        title: 'Permissions',
        action: 'security',
        link: { name: 'config.permissions' },
        permission: 'user-permissions',
      },
      {
        title: 'Roles',
        action: 'mdi-account-card-details-outline',
        link: { name: 'config.roles' },
        permission: 'user-roles',
      }
    ],
    permission: 'user',
  },
  {
    title: 'Local Configuration',
    action: 'settings',
    link: { name: 'config.local' },
    permission: 'local-configuration',
  },
  {
    title: 'Client Configuration',
    action: 'mdi-desktop-classic',
    link: { name: 'config.client' },
    permission: 'client-configuration',
  }
]

export let moduleRoute = [
  {
    path: '/configuration/Users',
    name: 'config.users',
    component: () => import('./Users')
  },
  {
    path: '/configuration/Permissions',
    name: 'config.permissions',
    component: () => import('./Permissions')
  },
  {
    path: '/configuration/Roles',
    name: 'config.roles',
    component: () => import('./Roles')
  },
  {
    path: '/configuration/Local',
    name: 'config.local',
    component: () => import('./Local')
  },
  {
    path: '/configuration/Client',
    name: 'config.client',
    component: () => import('./Client')
  },
]