<template lang="html">
  <v-card>
    <v-card-title primary-title class="pa-2" dark>
      <span class="font-weight-bold">ข้อมูลบัตรประจำตัวประชาชน</span>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-container fluid>
        <v-row dense>
          <v-col cols="3" d-flex class="card-small-padding">
            <v-row>
              <v-col cols="12">
                <v-card tile flat dark>
                  <v-card-text class="pa-2">
                    <v-img :src="cardPhoto"></v-img>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">เลขประจำตัวประชาชน</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardData.NationalID }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">ชื่อตัวและชื่อสกุล</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardThaiName }}</span><br /><span class="text-truncate">{{ cardEnglishName }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">เกิดวันที่</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardData.Birthdate }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">ที่อยู่</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardAddress }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">วันออกบัตร</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardData.IssueDate }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">ออก ณ</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardData.IssuePlace }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="4" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">วันบัตรหมดอายุ</span>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="8" class="card-small-padding">
                <v-card dark tile flat>
                  <v-card-text class="pa-2">
                    <span class="text-truncate">{{ cardData.ExpireDate }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { isUndefined,isEmpty } from 'lodash'

export default {
  props: {
    cardData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    cardPhoto() {
      return 'data:image/png;base64, ' + this.cardData.Base64Photo
    },
    cardThaiName() {
      let ThaiName = []
      if (!isEmpty(this.cardData.ThaiTitleName)) { ThaiName.push(this.cardData.ThaiTitleName) }
      if (!isEmpty(this.cardData.ThaiFirstName)) { ThaiName.push(this.cardData.ThaiFirstName) }
      if (!isEmpty(this.cardData.ThaiMiddleName)) { ThaiName.push(this.cardData.ThaiMiddleName) }
      if (!isEmpty(this.cardData.ThaiLastName)) { ThaiName.push(this.cardData.ThaiLastName) }

      return ThaiName.join(' ')
    },
    cardEnglishName() {
      let EnglishName = []
      if (!isEmpty(this.cardData.EnglishTitleName)) { EnglishName.push(this.cardData.EnglishTitleName) }
      if (!isEmpty(this.cardData.EnglishFirstName)) { EnglishName.push(this.cardData.EnglishFirstName) }
      if (!isEmpty(this.cardData.EnglishMiddleName)) { EnglishName.push(this.cardData.EnglishMiddleName) }
      if (!isEmpty(this.cardData.EnglishLastName)) { EnglishName.push(this.cardData.EnglishLastName) }

      return EnglishName.join(' ')
    },
    cardAddress() {
      let Address = []
      if (!isEmpty(this.cardData.Address)) { Address.push(this.cardData.Address) }
      if (!isEmpty(this.cardData.Moo)) { Address.push(this.cardData.Moo) }
      if (!isEmpty(this.cardData.Trok)) { Address.push(this.cardData.Trok) }
      if (!isEmpty(this.cardData.Soi)) { Address.push(this.cardData.Soi) }
      if (!isEmpty(this.cardData.Thanon)) { Address.push(this.cardData.Thanon) }
      if (!isEmpty(this.cardData.Tumbol)) { Address.push(this.cardData.Tumbol) }
      if (!isEmpty(this.cardData.Amphur)) { Address.push(this.cardData.Amphur) }
      if (!isEmpty(this.cardData.Province)) { Address.push(this.cardData.Province) }

      return Address.join(' ')
    }
  }
}
</script>

<style lang="css">
.card-small-padding {
  padding: 2px !important
}
</style>
