<template lang="html">
  <v-model-table model-name="Insurances" model-title="Insurances"
    :api-scope="scope"
    :model-api="['models','Registration','PatientsInsurances']"
    :model-api-search="{'hn': hn}" :initial-data="initialData"
    :headers="headers" v-if="hn"
    v-bind="$attrs" v-on="$listeners"
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="2" md="1">
            <v-text-field v-model="data.priority" label="ลำดับ" type="number" :rules="[rules.require(),rules.numeric()]"></v-text-field>
          </v-col>
          <v-col cols="10" md="3">
            <v-master-field v-model="data.payerType" label="ชนิดผู้ชำระค่าใช้จ่าย" group-key="payerType"></v-master-field>
          </v-col>
          <v-col cols="12" md="5">
            <v-master-field v-model="data.payerCode" label="ผู้รับผิดชอบชำระค่าใช้จ่าย" 
              :custom-api="['models','Master','Payers','query']" 
              custom-api-text="payerName" custom-api-value="payerCode"
              customApiEagerLoad
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-checkbox label="ผู้ป่วยสำรองจ่าย" v-model="data.isChargeToPatient"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="data.contractNo" label="เลขที่อ้างอิง/เลขที่กรมธรรม์"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-date-field v-model="data.beginDate" append-icon="event" label="วันที่เริ่มต้น" :rules="[rules.require()]"></v-date-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-date-field v-model="data.endDate" append-icon="event" label="วันที่สิ้นสุด"></v-date-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.limit" label="เพดานค่าใช้จ่ายรวม" :rules="[rules.numeric()]"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.limitPerOpd" label="เพดานค่าใช้จ่ายต่อครั้งผู้ป่วยนอก" :rules="[rules.numeric()]"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.limitPerIpd" label="เพดานค่าใช้จ่ายต่อครั้งผู้ป่วยใน" :rules="[rules.numeric()]"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field v-model="data.limitToConfirm" label="เพดานค่าใช้จ่ายขออนุมัติ" :rules="[rules.numeric()]"></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="data.payerType=='20'">
            <v-master-field v-model="data.nhsoHCodeMain" label="รหัสสถานพยาบาลหลัก (NHSO)"
              :custom-api="['models','EclaimMaster','Hospitals','query']" custom-api-text="HNAME" 
              custom-api-value="HMAIN" 
              clearable
              show-code
              :rules="[rules.requireIf(data.payerType=='20' && data.payerCode=='NHSO')]"
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3" v-if="data.payerType=='20'">
            <v-master-field v-model="data.nhsoHCode" label="รหัสโรงพยาบาลที่ส่งตัว (NHSO)"
              :custom-api="['models','EclaimMaster','Hospitals','query']" custom-api-text="HNAME" 
              custom-api-value="HMAIN" 
              clearable
              show-code
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3" v-if="data.payerType=='20'">
            <v-master-field v-model="data.nhsoCAGCode" label="รหัสกลุ่มโรคมะเร็ง (NHSO)"
              :custom-api="['models','EclaimMaster','CAGroups','query']" custom-api-text="CAGNAME" 
              custom-api-value="CAGCODE" 
              clearable customApiEagerLoad
              show-code
            ></v-master-field>
          </v-col>
          <v-col md="6" v-if="data.payerType=='20' && $vuetify.breakpoint.mdAndUp">
            &nbsp;
          </v-col>
          <v-col cols="12">
            <v-master-field v-model="data.clinics"
              :custom-api="['models','Master','Clinics','query']" custom-api-text="clinicName" 
              custom-api-value="clinicCode" label="ระบุคลินิก" 
              show-code
              customApiEagerLoad
              chips multiple
            ></v-master-field>
          </v-col>
          <v-col cols="12">
            <v-table-field v-model="data.policies" title="นโยบายประกัน/สิทธิ์" item-key="insuranceCode" :headers="policiesHeaders" hide-toolbar show-create-pad auto-item-action>
              <template v-slot:createPad="{data: tmplData,rules}">
                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="10">
                      <v-master-field :rules="[rules.require()]" v-model="tmplData.insuranceCode"
                        :custom-api="['models','Master','Insurances','query']" 
                        custom-api-text="insuranceName" custom-api-value="insuranceCode" 
                        label="Insurance Policy" 
                        customApiEagerLoad show-code>
                      </v-master-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field v-model="tmplData.priority" label="ลำดับ" :rules="[rules.require(),rules.numeric()]"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.insuranceName="props">
                <v-label-api :api-url="['models','Master','Insurances','find']" :api-key="props.item.insuranceCode" api-field="insuranceName"></v-label-api>
              </template>
            </v-table-field>            
          </v-col>
          <v-col cols="12" v-if="!isCreating && data.id">
            <v-document-scan :hn="data.hn" :referenceId="'insurance_'+data.id" folder="insurance" :available-category="['800']" default-category="800"></v-document-scan>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:toolBarButton>
      <v-insurance-check class="ml-1" :hn="hn"></v-insurance-check>
    </template>
    <template v-slot:item.priority="props">
      <span v-if="props.item.isTechnicalActive && props.item.isActive">{{props.item.priority}}</span>
      <v-icon v-else>mdi-lock-alert</v-icon>
    </template>
    <template v-slot:item.payer="props">
      <v-label-master group-key="payerType" :item-code="props.item.payerType"></v-label-master>
      <label v-if="props.item.payer">({{props.item.payer.payerName}})</label>
    </template>
    <template v-slot:item.beginDate="props">
      <v-label-datetime short-date :date-time="props.item.beginDate"></v-label-datetime>
    </template>
    <template v-slot:item.endDate="props">
      <v-label-datetime short-date :date-time="props.item.endDate"></v-label-datetime>
    </template>
    <template v-slot:item.isChargeToPatient="props">
      <v-icon class="text-center">{{ (props.item.isChargeToPatient) ? 'check' : 'close' }}</v-icon>
    </template>
    <template v-slot:item.limit="props">
      {{ (props.item.limit && props.item.limit>0) ? props.item.limit.toFixed(2) : '-' }}
    </template>
    <template v-slot:item.amount="props">
      <label class="body-2 font-weight-bold red--text text--darken-1" v-if="props.item.limit && props.item.limit>0 && props.item.amount>props.item.limit">
        {{ props.item.amount.toFixed(2) }}
      </label>
      <label class="body-2 font-weight-bold green--text text--darken-3" v-else>
        {{ props.item.amount.toFixed(2) }}
      </label>
    </template>
  </v-model-table>
  <v-card v-else>
    <v-card-text></v-card-text>
  </v-card>
</template>

<script>
import { find } from 'lodash'

export default {
  data: ()=>({
    headers: [
      {
        text: 'ลำดับ',
        value: 'priority',
        align: 'center',
        class: 'body-2',
        width: '100px'
      },
      {
        text: 'สิทธิ์/ประกัน',
        value: 'payer',
        class: 'body-2',
      },
      {
        text: 'สำรองจ่าย',
        value: 'isChargeToPatient',
        class: 'body-2',
      },
      {
        text: 'เริ่มต้น',
        value: 'beginDate',
        class: 'body-2'
      },
      {
        text: 'สิ้นสุด',
        value: 'endDate',
        class: 'body-2'
      },
      {
        text: 'Ref',
        value: 'contractNo',
        class: 'body-2'
      },
      {
        text: 'Limit',
        value: 'limit',
        class: 'body-2'
      },
      {
        text: 'ยอดเบิก',
        value: 'amount',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    policiesHeaders: [
      {
        text: 'รหัสนโยบาย',
        value: 'insuranceCode',
        class: 'body-2'
      },
      {
        text: 'ชื่อนโยบายประกัน/สิทธิ์',
        value: 'insuranceName',
        class: 'body-2'
      },
      {
        text: 'ลำดับ',
        value: 'priority',
        class: 'body-2'
      },
    ],
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    scope: {
      type: String,
      default: 'active'
    }
  },
  computed: {
    initialData() {
      return {
        isChargeToPatient: false,
        hn: this.hn,
      }
    }
  },
}
</script>

<style lang="css">
</style>
