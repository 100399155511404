<template lang="html">
  <label>
    อายุ {{ ageText }}
  </label>
</template>

<script>
import {isUndefined,isArray} from 'lodash'
import patient from '@/modules/helperPatient'

export default {
  props: {
    dateOfBirth: {
      type: String,
      default: undefined
    },
    showYear: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: true
    },
    showDay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ageText() {
      return patient.age(this.dateOfBirth,this.showYear,this.showMonth,this.showDay)
    }
  }
}
</script>

<style lang="css">
</style>
