<template lang="html">
  <v-card v-bind="$attrs" v-on="$listeners" color="white">
    <v-card-text v-if="currentPeriod" class="pa-0">
      <v-container py-3 fluid>
        <v-row>
          <v-col cols="12" md="6" align-self="center" class="headline font-weight-medium">
            <label>{{ cashierId }} :: </label>
            <v-label-datetime :date-time="currentPeriod.startDateTime" short-date-time></v-label-datetime>
          </v-col>
          <v-col cols="12" md="6" align-self="center" class="text-right">
            <v-action-dialog :ActivateButtonText="'Close period ฿'+currentPeriod.currentCash" formTitle="Close period" @saveData="closePeriod" maxWidth="40vw" :object="{cashierId: cashierId}">
              <template v-slot="{data,rules}">
                <v-container>
                  <v-row>
                    <v-col>
                       <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.finalCash" label="จำนวนเงินสดนำส่ง" suffix="บาท"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-action-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-else-if="cashierId" class="pa-0">
      <v-container py-3 fluid>
        <v-row>
          <v-col cols="12" md="6" align-self="center" class="headline font-weight-medium">
            <label>{{ cashierId }}</label>
          </v-col>
          <v-col cols="12" md="6" align-self="center" class="text-right">
            <v-action-dialog ActivateButtonText="New period" formTitle="New period" @saveData="newPeriod" maxWidth="40vw" :object="{cashierId: cashierId}">
              <template v-slot="{data,rules}">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field :rules="[rules.require(),rules.numeric()]" v-model="data.initialCash" label="จำนวนเงินสดเริ่มต้น" suffix="บาท"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-action-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text v-else class="pa-0">
      <v-container py-3 fluid>
        <v-row>
          <v-col cols="12" align-self="center">
            <label class="headline">No Cashier ID is configed for this client</label>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import api from 'tantee-common/lib/api'
import {isUndefined} from 'lodash'
import printDocument from '@/components/mixins/printDocument'

export default {
  mixins: [printDocument],
  data: ()=>({
    location: {},
    cashierId: undefined,
    currentPeriod: undefined,
  }),
  methods: {
    loadData() {
      if (!isUndefined(this.cashierId)) {
        let searchData = {
          cashierId: this.cashierId,
        }
        api.post(['Accounting','CashierController','getCashierPeriod'],searchData).then((returnData)=>{
          if (returnData.success) {
            this.currentPeriod = returnData.data
            this.$emit('periodLoaded',this.currentPeriod)
          } else {
            this.currentPeriod = undefined
          }
        })
        .catch((e)=>{
          void e
          this.currentPeriod = undefined
        })
      }
    },
    newPeriod(periodData,callback=undefined) {
      api.post(['Accounting','CashierController','addCashierPeriod'],periodData).then((returnData)=>{
        if (returnData.success) {
          this.currentPeriod = returnData.data
          this.$emit('periodLoaded',this.currentPeriod)
          if (!isUndefined(callback)) callback.done()
        } else {
          if (!isUndefined(callback)) callback.error(returnData.errorTexts)
        }
      })
      .catch((error)=> {
        if (!isUndefined(callback)) callback.error(error.errorTexts)
      })
    },
    closePeriod(periodData,callback=undefined) {
      api.post(['Accounting','CashierController','closeCashierPeriod'],periodData).then((returnData)=>{
        if (returnData.success) {
          this.printTemporary(undefined,undefined,'cashier_period_summary',returnData.data)
          this.$emit('periodUnloaded',returnData.data)
          if (!isUndefined(callback)) callback.done()
        } else {
          if (!isUndefined(callback)) callback.error(returnData.errorTexts)
        }
      })
      .catch((error)=> {
        if (!isUndefined(callback)) callback.error(error.errorTexts)
      })
      .finally(()=>{
        this.loadData()
      })
    },
    printPeriod(id) {
      let periodData = {
        id: id
      }
      api.post(['Accounting','CashierController','getCashierPeriodSummary'],periodData).then((returnData)=>{
        if (returnData.success) {
          this.printTemporary(undefined,undefined,'cashier_period_summary',returnData.data)
        }
      })
      .catch((error)=> {
        void error
      })
    }
  },
  mounted() {
    this.location = this.$store.getters.getConfig('location')
    if (this.location && this.location.cashierId) this.cashierId = this.location.cashierId
    this.loadData()
  }
}
</script>

<style lang="css" scoped>
</style>
