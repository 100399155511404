<template lang="html">
  <label style="white-space: pre;">{{ cautionText }}</label>
</template>

<script>
import prescription from '@/modules/helperPrescription'

export default {
  props: {
    cautions: {
      type: [String,Array],
      default: undefined
    },
  },
  asyncComputed: {
    cautionText() {
      return new Promise((resolve)=>{
        prescription.cautionsToText(this.cautions).then((result)=>{
          resolve(result)
        }).catch((e)=>{
          void e
          resolve(this.cautions)
        })
      })
    }
  },
}
</script>

<style lang="css">
</style>
