import { render, staticRenderFns } from "./vDocumentScan.vue?vue&type=template&id=75830cfa&scoped=true&lang=html&"
import script from "./vDocumentScan.vue?vue&type=script&lang=js&"
export * from "./vDocumentScan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75830cfa",
  null
  
)

export default component.exports