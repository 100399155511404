import api from 'tantee-common/lib/api'
import * as helper from '@/modules/helperAsset'

export function getBase64data(asset) {
  return new Promise((resolve,reject)=>{
    api.post(['Asset','AssetController','getAssetBase64'],asset).then((returnData)=>{
      if (returnData.success) {
        resolve(returnData.data)
      } else {
        reject(returnData.errorTexts)
      }
    })
  })
}

export default helper
