<template lang="html">
  <v-form-pad ref="productLaboratory" v-model="subFormData" :disabled="disabled">
    <template v-slot="{data, rules}">
      <v-container pa-0 fluid>
        <v-row>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.labCode" label="Lab Code"></v-text-field>
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field v-model="data.turnAroundTime" :rules="[rules.numeric()]" label="Turn around time"  suffix="นาที"></v-text-field>
          </v-col>
          <v-col md="6">
            <v-checkbox v-model="data.isConfidential" label="Confidential lab"></v-checkbox>
          </v-col>
          <v-col cols="12" md="9">
            <v-master-field v-model="data.allowedSpecimenType" label="Allowed Specimen Type"
              groupKey="labSpecimenType"
              multiple
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-master-field v-model="data.defaultSpecimenType" label="Default Specimen Type"
              :showOnly="data.allowedSpecimenType"
              groupKey="labSpecimenType"
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="9">
            <v-master-field v-model="data.allowedSpecimenContainer" label="Allowed Specimen Container"
              groupKey="labSpecimenContainer"
              multiple
            ></v-master-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-master-field v-model="data.defaultSpecimenContainer" label="Default Specimen Container"
              :showOnly="data.allowedSpecimenContainer"
              groupKey="labSpecimenContainer"
            ></v-master-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-form-pad>
</template>

<script>
import subForm from '@/components/mixins/subForm'
export default {
  mixins: [subForm],
}
</script>

<style lang="css">
</style>
