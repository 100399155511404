export let moduleMenu = [
  {
    title: 'Outpatient Service',
    action: 'local_hospital',
    menuItems: [
      {
        title: 'Screening',
      },
      {
        title: 'Doctor room',
        permission: 'opd-doctor-room',
        link: { name: 'opd.doctorroom' },
      },
      {
        title: 'Nurse counter',
        permission: 'opd-nurse-counter',
        link: { name: 'opd.nursecounter' },
      },
    ],
    permission: 'opd',
  },
]

export let moduleRoute = [
  {
    path: '/opd/DoctorRoom',
    name: 'opd.doctorroom',
    component: () => import('./DoctorRoom')
  },
  {
    path: '/opd/NurseCounter',
    name: 'opd.nursecounter',
    component: () => import('./NurseCounter')
  },
  {
    path: '/opd/Transactions',
    name: 'opd.transactions',
    component: () => import('./Transactions')
  },
  {
    path: '/opd/DocumentNotes',
    name: 'opd.documentnotes',
    component: () => import('./DocumentNotes')
  },
  {
    path: '/opd/Document.vue',
    name: 'test',
    component: () => import('./Document.vue')
  },
]