export let moduleMenu = [
  {
    title: 'MasterData Configuration',
    action: 'library_books',
    menuItems: [
      {
        title: 'Clinics and locations',
        action: 'local_hospital',
        link: { name: 'master.clinicslocations' },
        permission: 'master-clinics-locations',
      },
      {
        title: 'Doctors',
        action: 'face',
        link: { name: 'master.doctors' },
        permission: 'master-doctors',
      },
      {
        title: 'Period and Duty types',
        action: 'schedule',
        link: { name: 'master.periodsduties' },
        permission: 'master-doctors-periods',
      },
      {
        title: 'Discount Vouchers',
        action: 'mdi-ticket-percent',
        link: { name: 'master.vouchers' },
        permission: 'master-voucher',
      },
      {
        title: 'Payer and Insurances',
        action: 'monetization_on',
        link: { name: 'master.insurances' },
        permission: 'master-insurance',
      },
      {
        title: 'Products and Services',
        action: 'store',
        link: { name: 'master.productsservices' },
        permission: 'master-product',
      },
      {
        title: 'Document Templates',
        action: 'description',
        link: { name: 'master.documents' },
        permission: 'master-document',
      },
      {
        title: 'Other MasterData',
        action: 'list_alt',
        link: { name: 'master.masterdata' },
        permission: 'master-others'
      },
    ],
    permission: 'master',
  },
]

export let moduleRoute = [
  {
    path: '/master/ClinicsLocations',
    name: 'master.clinicslocations',
    component: () => import('./ClinicsLocations')
  },
  {
    path: '/master/PeriodsDuties',
    name: 'master.periodsduties',
    component: () => import('./PeriodsDuties')
  },
  {
    path: '/master/Doctors',
    name: 'master.doctors',
    component: () => import('./Doctors')
  },
  {
    path: '/master/Vouchers',
    name: 'master.vouchers',
    component: () => import('./Vouchers')
  },
  {
    path: '/master/Insurances',
    name: 'master.insurances',
    component: () => import('./Insurances')
  },
  {
    path: '/master/ProductsServices',
    name: 'master.productsservices',
    component: () => import('./ProductsServices')
  },
  {
    path: '/master/Documents',
    name: 'master.documents',
    component: () => import('./Documents')
  },
  {
    path: '/master/MasterGroup',
    name: 'master.mastergroup',
    component: () => import('./MasterGroups')
  },
  {
    path: '/master/MasterData',
    name: 'master.masterdata',
    component: () => import('./MasterData')
  },
]