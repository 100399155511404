<template lang="html">
  <v-model-table model-name="Locations" model-title="Locations"
    :model-api="['models','Master','Locations']" model-key="locationCode"
    :headers="headers"
    importable
  >
    <template v-slot:modelForm="{data,rules,isCreating}">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <v-radio-group v-model="data.locationType" :rules="[rules.require()]" row  :disabled="!isCreating">
                  <v-radio label="แผนกผู้ป่วยนอก" value="opd"></v-radio>
                  <v-radio label="หอผู้ป่วย" value="ward"></v-radio>
                  <v-radio label="สถานที่ให้บริการอื่นๆ" value="facility"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="data.locationCode" :rules="[rules.require()]" label="รหัสสถานที่" :disabled="!isCreating"></v-text-field>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field v-model="data.locationName" :rules="[rules.require()]" label="ชื่อสถานที่"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="data.locationDescription" label="คำอธิบายตำแหน่ง" auto-grow></v-textarea>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="data.locationTelephone" :rules="[rules.require()]" label="เบอร์โทรศัพท์"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-table-field v-model="data.subunits" title="สถานที่ย่อย" :headers="subUnitHeaders" item-key="subunitCode" hide-toolbar show-create-pad auto-item-action flat>
              <template v-slot:createPad="{data: padData,rules}">
                <v-container fluid pa-0>
                  <v-row>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="padData.subunitCode" :rules="[rules.require(),rules.unique(data.subUnits,'subUnitCode')]" label="รหัสสถานที่ย่อย"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="padData.subunitName" :rules="[rules.require()]" label="ชื่อสถานที่ย่อย"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="padData.subunitTelephone" label="เบอร์โทรศัพท์"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-table-field>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-model-table>
</template>
<script>
export default {
  data: ()=>({
    headers: [
      {
        text: 'รหัสสถานที่',
        value: 'locationCode',
        class: 'body-2',
        width: '200px'
      },
      {
        text: 'ชื่อสถานที่',
        value: 'locationName',
        class: 'body-2'
      },
      {
        text: 'คำอธิบายตำแหน่ง',
        value: 'locationDescription',
        class: 'body-2'
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'locationTelephone',
        class: 'body-2'
      },
      {
        text: 'Action',
        value: 'action',
        align: 'center',
        width: '150px',
        sortable: false,
        class: 'body-2'
      }
    ],
    subUnitHeaders: [
      {
        text: 'รหัสสถานที่ย่อย',
        value: 'subunitCode',
        class: 'body-2',
        width: '200px',
      },
      {
        text: 'ชื่อสถานที่ย่อย',
        value: 'subunitName',
        class: 'body-2',
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'subunitTelephone',
        class: 'body-2',
      },
    ],
  }),
}
</script>

<style lang="css">
</style>
