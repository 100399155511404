import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

import alert from 'tantee-common/lib/framework/stores/alert'
import master from 'tantee-common/lib/framework/stores/master'
import authentication from 'tantee-common/lib/framework/stores/authentication-sanctum'

import helperClient from '@/modules/helperClient'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    computerName: undefined,
    config: {},
    fatalError: [],
    accessToken: '',
    refreshToken: '',
  },
  mutations: {
    setComputerName(state,Item) {
      state.computerName = Item
    },
    addConfig(state,Item) {
      if (!_.isUndefined(Item.configName)) {
        let oldConfig = localStorage.getItem('cfg_' + Item.configName)
        let newConfig = Item.configItems

        if (_.isPlainObject(Item.configItems)) {
          try {
            oldConfig = JSON.parse(oldConfig)
          } catch(e) {
            void e
            oldConfig = {}
          }
          
          newConfig = Object.assign({}, oldConfig, newConfig)
          localStorage.setItem('cfg_' + Item.configName, JSON.stringify(newConfig))

          Vue.set(state.config, Item.configName, newConfig)
        } else {
          localStorage.setItem('cfg_' + Item.configName, newConfig)

          Vue.set(state.config, Item.configName, newConfig)
        }
        helperClient.setMachineConfig(Item.configName,newConfig)
      }
    },
    addDefaultConfig(state,Item) {
      if (!_.isUndefined(Item.configName)) {
        let oldConfig = localStorage.getItem('cfg_' + Item.configName)
        let newConfig = Item.configItems

        if (_.isPlainObject(Item.configItems)) {
          try {
            oldConfig = JSON.parse(oldConfig)
          } catch(e) {
            void e
            oldConfig = {}
          }

          newConfig = Object.assign({}, newConfig, oldConfig)
          localStorage.setItem('cfg_' + Item.configName, JSON.stringify(newConfig))

          Vue.set(state.config, Item.configName, newConfig)
        } else if (_.isUndefined(oldConfig) || oldConfig == null) {
          localStorage.setItem('cfg_' + Item.configName, newConfig)

          Vue.set(state.config, Item.configName, newConfig)
        } else {
          Vue.set(state.config, Item.configName, oldConfig)
        }

        helperClient.setMachineConfig(Item.configName,newConfig)
      }
    },
    addFatalError(state,Item) {
      let newItems = state.fatalError
      newItems.push(Item)
      state.fatalError = newItems
    }
  },
  getters: {
    getComputerName: (state)=>{
      return state.computerName
    },
    getConfig: (state) => (configName) => {
      let storedConfig = localStorage.getItem('cfg_' + configName)
      let returnConfig = storedConfig
      try {
        returnConfig = JSON.parse(storedConfig);
      } catch (e) {
        void e
        returnConfig = storedConfig
      }
      return returnConfig;
    },
    getFatalError: (state) => {
      return state.fatalError
    }
  },
  modules: {
    alert,
    master,
    authentication
  }
})
